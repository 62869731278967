import { createContext, useContext } from 'react'
import { GetAppDetailsVariables } from '../../graphql/appDetails'
import { SupportedClusters } from '../../../src/graphql/statuses'

export const EnvironmentContext = createContext<GetAppDetailsVariables>({
  appName: '',
  namespace: '',
  cluster: SupportedClusters.NoCluster,
})
export const useEnv = () => useContext(EnvironmentContext)
