import React from 'react'
import { Popup } from '@wework/dieter-ui'
import PropTypes from 'prop-types'
import { Dashboards } from '../../graphql/appDetails'
import './Dashboards.css'

interface DashboardsPopupProps {
  dashboards: Dashboards
}

export const DashboardsPopup: React.FunctionComponent<DashboardsPopupProps> = ({
  dashboards,
}) => {
  let { weapm, userDefined } = dashboards

  weapm = weapm ? weapm : []
  userDefined = userDefined ? userDefined : []

  const renderDashboardLinks = () => {
    return (
      <ul>
        {dashboards.trafficGatewayLink && (
          <li className="traffic-gateway">
            <a
              href={dashboards.trafficGatewayLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Traffic Gateway
            </a>
          </li>
        )}
        {weapm.map(service => {
          return (
            <li className="weapm" key={service.serviceName}>
              <i>{service.serviceName}</i>
              <br />
              WeAPM:{' '}
              <a
                href={service.elkLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                ELK
              </a>
              ,{' '}
              <a
                href={service.grafanaLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Grafana
              </a>
            </li>
          )
        })}
        {userDefined.map(x => {
          return (
            <li className="user-defined" key={x.name}>
              <a href={x.link} target="_blank" rel="noopener noreferrer">
                {x.name}
              </a>
            </li>
          )
        })}
      </ul>
    )
  }

  const otherHelpfulDashboads = () => {
    const storageDashboards: { [key: string]: string } = {
      MySQL:
        'https://grafana-us.weworkers.io/dashboards/f/570/ct-277-storage-mysql',
      PostgreSQL:
        'https://grafana-us.weworkers.io/dashboards/f/650/ct-277-storage-postgresql',
      Redis: 'https://grafana-us.weworkers.io/d/5U6r8toWk/redis-overview',
      ElasticSearch:
        'https://grafana-us.weworkers.io/d/2E6SpH6Wz/elasticsearch-overview',
    }

    return (
      <React.Fragment>
        <span>Other relevant dashboard groups (not specific to this app)</span>
        <ul>
          {Object.entries(storageDashboards).map(val => {
            const [name, link] = val
            return (
              <li key={`other-dashboards-${name}`}>
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {name}
                </a>
              </li>
            )
          })}
        </ul>
      </React.Fragment>
    )
  }

  const renderHelpText = () => {
    return (
      <span className="how-to-enable-dashboards">
        {weapm.length === 0 && (
          <p className="weapm-help">
            <span>
              You seem to not have installed WeAPM on any of your services.
              Click{' '}
              <a
                href="https://connect.weworkers.io/display/TDP/WeAPM"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>{' '}
              to find out how to enable this functionality!
            </span>
          </p>
        )}
        {userDefined.length === 0 && (
          <p className="user-defined-help">
            If you would like to link to your own custom dashboards, you can
            update your descriptor{' '}
            <a
              href="https://github.com/WeConnect/wectl/blob/6a96a235b0a2024127df1a8215cc4feeeaf9fd20/examples/descriptor.yaml#L11-L16"
              target="_blank"
              rel="noopener noreferrer"
            >
              like so
            </a>
            , and they will show up here.
          </p>
        )}
      </span>
    )
  }

  return (
    <Popup
      trigger={
        <span className="external-dashboards-prompt">
          ... Looking for your other dashboards?
        </span>
      }
      on={['click', 'hover']} // have to add click, b/c tests don't work on hover
      content={
        <span className="external-dashboards-popup">
          Here are the other dashboards configured for this app:
          {renderDashboardLinks()}
          {otherHelpfulDashboads()}
          {renderHelpText()}
        </span>
      }
      basic
      hoverable
    />
  )
}

DashboardsPopup.propTypes = {
  dashboards: PropTypes.any.isRequired,
}

export default DashboardsPopup
