import React, { useState } from 'react'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import PropTypes from 'prop-types'
import './EnvVariables.css'
import {
  LIST_ENV_VARIABLES,
  READ_ENV_VARIABLE,
  WRITE_ENV_VARIABLE,
  DELETE_ENV_VARIABLE,
  ListEnvVariablesArgs,
  ListEnvVariablesQueryResult,
  ReadEnvVariableArgs,
  ReadEnvVariableResult,
  WriteEnvVariableArgs,
  WriteEnvVariableResult,
  DeleteEnvVariableArgs,
} from '../../graphql/appDetails'
import { delay, getGraphQLErrorMessage } from '../../graphql/helpers'
import ErrorPopup from '../Error/Error'
import { useEnv } from '../Contexts/EnvironmentContext'
import NavProps from '../../pages/app-detail-view/nav-props'
import EnvVariablesView from './EnvVariablesView'

export interface StateProps {
  [key: string]: {
    value: string
    loading: boolean
    showRestartWarning: boolean
  }
}

export const EnvVariables: React.FunctionComponent<{
  nav: NavProps
}> = props => {
  const envContext = useEnv()
  const [state, setState] = useState<StateProps>({})
  const [errorState, setErrorState] = useState<string>('')
  const client = useApolloClient()

  const { loading: initialLoad, data: listData, refetch } = useQuery<
    ListEnvVariablesQueryResult,
    ListEnvVariablesArgs
  >(LIST_ENV_VARIABLES, {
    variables: envContext,
  })

  const [writeEnvVar] = useMutation<
    WriteEnvVariableResult,
    WriteEnvVariableArgs
  >(WRITE_ENV_VARIABLE)

  const [deleteEnvVar] = useMutation<void, DeleteEnvVariableArgs>(
    DELETE_ENV_VARIABLE,
  )

  const writeEnvVarCallback = async (key: string, value: string) => {
    const { cluster, namespace, appName } = envContext

    try {
      setState({
        ...state,
        ...{ [key]: { value: '', loading: true, showRestartWarning: false } },
      })
      const res = await writeEnvVar({
        variables: { cluster, namespace, appName, key, value },
      })
      if (!res.data || !res.data.writeEnvVariable) {
        throw new Error(`writeEnvVariable responded with empty status`)
      }
      setState({
        ...state,
        ...{
          [key]: { value, loading: false, showRestartWarning: true },
        },
      })
      void delay(refetch, 5000)
    } catch (e) {
      setErrorState(getGraphQLErrorMessage(e))
      setState({
        ...state,
        ...{ [key]: { value: '', loading: false, showRestartWarning: false } },
      })
    }
  }

  const readEnvVarCallback = async (key: string) => {
    const { cluster, namespace, appName } = envContext
    let newValue = ''
    try {
      setState({
        ...state,
        ...{ [key]: { ...state[key], ...{ loading: true } } },
      })
      const { data } = await client.query<
        ReadEnvVariableResult,
        ReadEnvVariableArgs
      >({
        query: READ_ENV_VARIABLE,
        variables: { cluster, namespace, appName, key },
      })

      if (!data || !data.readEnvVariable) {
        throw new Error(`readEnvVariable responded with empty status`)
      }
      newValue = data.readEnvVariable.value
      setState({
        ...state,
        ...{
          [key]: { value: newValue, loading: false, showRestartWarning: false },
        },
      })
    } catch (e) {
      setErrorState(getGraphQLErrorMessage(e))
      setState({
        ...state,
        ...{ [key]: { ...state[key], ...{ loading: false } } },
      })
    }
  }

  const deleteEnvVarCallback = async (key: string, value: string) => {
    const { cluster, namespace, appName } = envContext

    try {
      setState({
        ...state,
        ...{
          [key]: { value, loading: true, showRestartWarning: false },
        },
      })
      await deleteEnvVar({
        variables: { cluster, namespace, appName, key },
      })

      const updatedState = { ...state }
      delete updatedState[key]
      setState(updatedState)
    } catch (e) {
      setErrorState(getGraphQLErrorMessage(e))
      setState({
        ...state,
        ...{
          [key]: { value, loading: false, showRestartWarning: false },
        },
      })
    }
  }

  if (initialLoad) return <div />

  if (listData && Object.keys(state).length === 0) {
    const keys = listData.listEnvVariables.keys || []
    const sortedKeys = keys
      .slice()
      .sort((a, b) => (a.toUpperCase() > b.toUpperCase() ? 1 : -1))
    const result = sortedKeys.reduce((acc: StateProps, key: string) => {
      acc[key] = { value: '', loading: false, showRestartWarning: false }
      return acc
    }, {})
    setState(result)
  }

  return (
    <React.Fragment>
      <EnvVariablesView
        nav={props.nav}
        variablesList={state}
        {...{ readEnvVarCallback, writeEnvVarCallback, deleteEnvVarCallback }}
      />
      {errorState !== '' ? <ErrorPopup>{errorState}</ErrorPopup> : null}
    </React.Fragment>
  )
}

EnvVariables.propTypes = {
  nav: PropTypes.any.isRequired,
}

export default EnvVariables
