import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from 'semantic-ui-react'
import './app-detail-view.css'
import { AppDeployment } from '../../graphql/appDetails'
import NoData from '../../components/NoData/NoData'
import DeployOverTimeHeader from './deploy-over-time-header.component'
import DeployOverTime from './deploy-over-time.component'
import NavProps from './nav-props'

interface DeploysOverTimeProps {
  deploymentList: AppDeployment[]
  activeDeployment: AppDeployment | undefined
  nav: NavProps
}

export const DeploysOverTime: React.FunctionComponent<React.PropsWithChildren<
  DeploysOverTimeProps
>> = props => {
  return (
    <div className="a-d-v-box" id={props.nav.id}>
      <p className="p box-header">
        <span className="details-section-title">{props.nav.title}</span>
      </p>
      {!props.deploymentList || props.deploymentList.length === 0 ? (
        <NoData>No Deployments found for this App</NoData>
      ) : (
        <Grid columns={2}>
          <Grid.Column>
            <DeployOverTimeHeader />
            {props.deploymentList?.slice(0, 5).map((test, i) => (
              <DeployOverTime
                key={i + 1}
                order={i + 1}
                activeDeploy={props.activeDeployment}
                isActive={test.isActive}
                githubLink={test.githubLink}
                githubRevision={test.githubRevision}
                creationTimestamp={test.creationTimestamp}
              />
            ))}
          </Grid.Column>
          <Grid.Column>
            <DeployOverTimeHeader />
            {props.deploymentList?.slice(5, 10).map((test, i) => (
              <DeployOverTime
                key={i + 6}
                order={i + 6}
                activeDeploy={props.activeDeployment}
                isActive={test.isActive}
                githubLink={test.githubLink}
                githubRevision={test.githubRevision}
                creationTimestamp={test.creationTimestamp}
              />
            ))}
          </Grid.Column>
        </Grid>
      )}
    </div>
  )
}

DeploysOverTime.propTypes = {
  nav: PropTypes.any.isRequired,
  deploymentList: PropTypes.any.isRequired,
  activeDeployment: PropTypes.any.isRequired,
}

export default DeploysOverTime
