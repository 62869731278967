import React from 'react'
import PropTypes from 'prop-types'
// @ts-ignore
import { useOktaAuth } from '@okta/okta-react'

interface OktaLoginProps {
  redirectUrl: string
}

export const OktaLogin: React.FunctionComponent<React.PropsWithChildren<
  OktaLoginProps
>> = props => {
  const { authService, authState } = useOktaAuth()
  if (authState.isPending) {
    return <div>Loading...</div>
  }

  const login = () => authService.login(props.redirectUrl || '/')
  return (
    <button type="button" className="ui primary button" onClick={login}>
      Login
    </button>
  )
}

OktaLogin.propTypes = {
  redirectUrl: PropTypes.string.isRequired,
}
