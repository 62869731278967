import React from 'react'
import * as _ from 'lodash'
import { useState, useEffect, useMemo, SyntheticEvent } from 'react'
import '../../App.css'
import Loader from 'react-loader-spinner'
import { useQuery } from '@apollo/client'
import { Button, Grid, Input } from '@wework/dieter-ui'
import { useHistory, useParams } from 'react-router-dom'

import { v1 as generateUuidV1 } from 'uuid'
import TopBar from '../../components/TopBar'
import AppBar from '../../components/AppBar/AppBar'
import AppBarHeader from '../../components/AppBarHeader/AppBarHeader'
import AnimateGroup from '../../components/Animations/AnimateGroup'
import NoData from '../../components/NoData/NoData'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

import {
  GET_STATUSES as query,
  AppStatus,
  ServiceStatus,
  SupportedClusters,
  GetAppStatusesQueryResult,
  GetAppStatusesVariables,
} from '../../graphql/statuses'
import { urlToAppStatusList, useLocationQuery } from '../../routes'
import { AppliedFilters } from './applied-filters'
import { FilterParams, FilterLocationState } from './filter-params'

const appliedFilters = new AppliedFilters()
const filterObserverUuid = generateUuidV1()

interface LocationParams {
  cluster?: string
}

interface DefaultFilters {
  cluster: string
  namespaces: string[]
  searchQuery: string
}

class AppliedFilterStates {
  cluster: string
  namespaces: string[]
  searchQuery: string

  constructor(params: DefaultFilters) {
    this.cluster = params.cluster
    this.namespaces = params.namespaces
    this.searchQuery = params.searchQuery
  }

  updateCluster(cluster: string): AppliedFilterStates {
    return new AppliedFilterStates({
      ...this.getAttributes(),
      cluster,
    })
  }

  updateNamespaces(namespaces: string[]): AppliedFilterStates {
    return new AppliedFilterStates({
      ...this.getAttributes(),
      namespaces,
    })
  }

  updateSearchQuery(searchQuery: string): AppliedFilterStates {
    return new AppliedFilterStates({
      ...this.getAttributes(),
      searchQuery,
    })
  }

  private getAttributes() {
    return {
      cluster: this.cluster,
      namespaces: this.namespaces,
      searchQuery: this.searchQuery,
    }
  }
}

export const AppStatusList: React.SFC<React.Props<any>> = _props => {
  const history = useHistory()
  const locationParams = new FilterParams(
    useParams<LocationParams>().cluster || SupportedClusters.ProdUsEast,
    useLocationQuery(),
  )

  const [filteredData, setFilteredData] = useState<AppStatus[]>([])
  const [appliedFilterStates, setAppliedFilterStates] = useState<
    AppliedFilterStates
  >(new AppliedFilterStates(locationParams))

  const [namespaceList, setNamespaceList] = useState<string[]>([])
  const [downApps, setDownApps] = useState<AppStatus[]>([])

  const { loading, data } = useQuery<
    GetAppStatusesQueryResult,
    GetAppStatusesVariables
  >(query, {
    variables: {
      cluster: appliedFilterStates.cluster,
    },
    pollInterval: 60000,
  })

  history.listen(({ state }) => {
    const event = (state as FilterLocationState)?.changedEvent

    if (event != null) {
      setAppliedFilterStates(new AppliedFilterStates(event))
    }
  })

  appliedFilters.subscribe(filterObserverUuid, changedEvent => {
    history.push(urlToAppStatusList(changedEvent), { changedEvent })
  })

  useEffect(() => {
    if (appliedFilterStates.cluster == null) return

    appliedFilters.setCluster(appliedFilterStates.cluster)
    appliedFilters.setNamespaces(appliedFilterStates.namespaces)
    appliedFilters.setSearchQuery(appliedFilterStates.searchQuery)
  })

  useEffect(() => {
    if (!data) {
      setDownApps([])
      return
    }
    const appStatuses = data.getStatuses
    setDownApps(appStatuses.filter(app => app.status !== ServiceStatus.UP))
  }, [data])

  useEffect(() => {
    if (!data) return
    const appStatuses = data.getStatuses

    setNamespaceList(
      appStatuses
        .map(appstatus => appstatus.namespace)
        .concat(appliedFilterStates.namespaces)
        .filter((value, index, self) => self.indexOf(value) === index),
    )

    function getFilteredArray() {
      if (
        appliedFilterStates.searchQuery.length === 0 &&
        appliedFilterStates.cluster === '' &&
        appliedFilterStates.namespaces.length === 0
      ) {
        return appStatuses
      }

      return _.filter(appStatuses, app => {
        if (
          (appliedFilterStates.cluster !== '' &&
            app.cluster !== appliedFilterStates.cluster) ||
          (appliedFilterStates.namespaces.length !== 0 &&
            !appliedFilterStates.namespaces.includes(app.namespace))
        ) {
          return false
        }
        return app.name
          .toLowerCase()
          .includes(appliedFilterStates.searchQuery.toLowerCase())
      })
    }

    setFilteredData(getFilteredArray())
  }, [appliedFilterStates, data])

  function debouncedSearch(): (
    _e: React.ChangeEvent<HTMLInputElement>,
    _v: any,
  ) => void {
    let timer: NodeJS.Timeout | null
    let searchString = ''

    return (
      _event: React.ChangeEvent<HTMLInputElement>,
      inputData: { value: string },
    ) => {
      searchString = inputData.value
      if (timer) return
      timer = setTimeout(() => {
        timer = null
        setAppliedFilterStates(
          appliedFilterStates.updateSearchQuery(searchString),
        )
      }, 500)
    }
  }

  function handleNamespaceSelection(
    _event: SyntheticEvent<HTMLElement, Event>,
    inputData: any,
  ): void {
    let ns: string[]

    if (Array.isArray(inputData.value)) {
      ns = inputData.value
    } else {
      ns = [inputData.value]
    }

    setAppliedFilterStates(appliedFilterStates.updateNamespaces(ns))
  }

  const environment =
    appliedFilterStates.cluster === SupportedClusters.ProdUsEast
      ? 'prod'
      : 'dev'

  return (
    <header className="app">
      <TopBar />
      <div className="app-body">
        <Grid textAlign="left" className="margin" columns={2}>
          <Grid.Column width={2}>
            <div>
              <Input
                className="fluid input-override"
                icon="search"
                placeholder="Search..."
                defaultValue={appliedFilterStates.searchQuery}
                onChange={debouncedSearch()}
              />

              <div className="cs-box">
                <Button
                  primary
                  value="West-Dev"
                  className={
                    appliedFilterStates.cluster === SupportedClusters.DevUsWest
                      ? 'cs-button active'
                      : 'cs-button'
                  }
                  onClick={() => {
                    setAppliedFilterStates(
                      appliedFilterStates.updateCluster(
                        SupportedClusters.DevUsWest,
                      ),
                    )
                  }}
                >
                  US West Dev
                </Button>
                <Button
                  primary
                  className={
                    appliedFilterStates.cluster === SupportedClusters.ProdUsEast
                      ? 'cs-button active'
                      : 'cs-button'
                  }
                  onClick={() => {
                    setAppliedFilterStates(
                      appliedFilterStates.updateCluster(
                        SupportedClusters.ProdUsEast,
                      ),
                    )
                  }}
                >
                  US East Prod
                </Button>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column width={14}>
            <Grid.Row>
              <p className="wectl-headers wectl-spacing">
                Wectl Apps Currently Experiencing Issues in {environment}
              </p>
              {!downApps ||
                (downApps.length === 0 && (
                  <NoData>
                    No WeCTL Apps Currently experiencing issues in {environment}
                  </NoData>
                ))}
              <AnimateGroup>
                {downApps.map(apps => (
                  <div key={apps.id}>{AppBar(apps)}</div>
                ))}
              </AnimateGroup>
              <p className="wectl-headers wectl-spacing">
                All WeCTL Apps in {environment}
              </p>
            </Grid.Row>
            <Grid.Row>
              <AppBarHeader
                namespace={namespaceList}
                defaultNS={appliedFilterStates.namespaces}
                onChange={handleNamespaceSelection}
              />
            </Grid.Row>
            <Grid.Row>
              {useMemo(
                () =>
                  loading ? (
                    <AnimateGroup>
                      <div style={{ textAlign: 'center', marginTop: '40px' }}>
                        <Loader
                          type="RevolvingDot"
                          color="#FFFFFF"
                          height={100}
                          width={100}
                          timeout={3000}
                        />
                        <div style={{ marginTop: '20px', color: 'white' }}>
                          Loading...
                        </div>
                      </div>
                    </AnimateGroup>
                  ) : (
                    filteredData &&
                    (filteredData.length > 0 ? (
                      <AnimateGroup>
                        {filteredData.map(appStatus => (
                          <div key={appStatus.id}>{AppBar(appStatus)}</div>
                        ))}
                      </AnimateGroup>
                    ) : (
                      <div className="error-notice">
                        <NoData>No Apps Found</NoData>
                      </div>
                    ))
                  ),
                [filteredData, loading],
              )}
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </div>
    </header>
  )
}
