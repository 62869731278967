import React from 'react'
import { Container, Grid, Image } from 'semantic-ui-react'
import { Text } from '@wework/dieter-ui'
import { Link } from 'react-router-dom'
import { AppStatus } from '../../graphql/statuses'
import Status from '../AppStatus/AppStatus'
import Github from './Github.svg'
import Kibana from './Kibana.svg'
import '../../App.css'

function AppBar(data: AppStatus) {
  return (
    <Container className="app-bar">
      <Grid columns={6}>
        <Grid.Column width={4}>
          <Link
            to={`/app-detail-view/${data.cluster}/${data.namespace}/${data.name}`}
          >
            <Text primary className="app-name">
              {data.name}
            </Text>
          </Link>
        </Grid.Column>
        <Grid.Column width={4}>
          <Text secondary>{data.namespace}</Text>
        </Grid.Column>
        <Grid.Column>
          <Text secondary>
            {data.conciergeTeamLink && (
              <React.Fragment>
                <a
                  href={data.conciergeTeamLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data.conciergeTeamId}
                </a>
              </React.Fragment>
            )}
          </Text>
        </Grid.Column>
        <Grid.Column>
          <Text secondary>{Status(data.status)}</Text>
        </Grid.Column>
        <Grid.Column>
          <Grid columns={2}>
            <Grid.Column>
              <Image
                src={Github}
                as="a"
                size="medium"
                href={data.gitRepo}
                target="_blank"
              />
            </Grid.Column>
            {data.trafficGatewayLink && (
              <Grid.Column>
                <Image
                  src={Kibana}
                  as="a"
                  size="medium"
                  href={data.trafficGatewayLink}
                  target="_blank"
                />
              </Grid.Column>
            )}
          </Grid>
        </Grid.Column>
      </Grid>
    </Container>
  )
}

export default AppBar
