import React from 'react'
import PropTypes from 'prop-types'
import { Card, Image } from 'semantic-ui-react'
import { OktaLogin } from './okta-login.component'
import We from './we.svg'
import './login.component.css'

export const Login: React.SFC<any> = props => {
  let redirectUrl = ''
  const from = props.location?.state?.from
  if (from) {
    const { pathname, search } = from
    redirectUrl = `${pathname}${search || ''}`
  }

  return (
    <div className="login-background">
      <Card centered={true} color="black" className="login-card">
        <Image className="login-image" src={We} wrapped />
        <Card.Content>
          <Card.Header className="login-text">WeCTL-UI</Card.Header>
          <Card.Description>
            <OktaLogin redirectUrl={redirectUrl} />
          </Card.Description>
        </Card.Content>
      </Card>
    </div>
  )
}

Login.propTypes = {
  location: PropTypes.object.isRequired,
}
