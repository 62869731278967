import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from 'semantic-ui-react'
import './app-detail-view.css'
import { Divider, Icon, Popup } from '@wework/dieter-ui'
import { FaCrown } from 'react-icons/fa'
import { AppDeployment } from '../../graphql/appDetails'

interface DeployOverTimeProps {
  order: number
  activeDeploy: AppDeployment | undefined
  isActive: boolean
  githubLink: string
  githubRevision: string
  creationTimestamp: string
}

export const DeployOverTime: React.FunctionComponent<React.PropsWithChildren<
  DeployOverTimeProps
>> = props => {
  function circleNumber() {
    return (
      <div className="circle">
        <div className="number">{props.order}</div>
      </div>
    )
  }

  function activeIcon() {
    if (
      props.activeDeploy &&
      props.isActive &&
      props.githubRevision === props.activeDeploy.githubRevision
    ) {
      return <FaCrown color="gold" />
    } else if (props.isActive) {
      return <Icon name="check" border color="blue" />
    }
    return ''
  }

  const revision = /^[\da-f]+$/i.test(props.githubRevision)
    ? props.githubRevision.slice(0, 6) // If it's a SHA, just use 6 characters
    : props.githubRevision

  return (
    <div>
      <Grid columns={5} className="deploys-text">
        <Grid.Column width={1}>
          <Popup
            trigger={<div>{activeIcon()}</div>}
            on="hover"
            content={
              <span>
                <p>
                  <FaCrown />: The most recent deployment that is running
                </p>
                <p>
                  <Icon name="check" border color="blue" />
                  :a deploy that is also running, but is not the most recent
                  deployment. Multiple active deploys is generally a symptom of
                  a failed deploy (or maybe if youre running canary deploys).
                </p>
              </span>
            }
            basic
          />
        </Grid.Column>
        <Grid.Column width={1}>{circleNumber()}</Grid.Column>
        <Grid.Column width={7} style={{ left: 8 }}>
          {props.creationTimestamp}
        </Grid.Column>
        <Grid.Column className="deployment-revision">{revision}</Grid.Column>
        <Grid.Column>
          {props.githubLink === '' ? (
            <span className="no-link">Config</span>
          ) : (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={props.githubLink}
            >
              Github
            </a>
          )}
        </Grid.Column>
      </Grid>
      <Divider />
    </div>
  )
}

DeployOverTime.propTypes = {
  order: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  activeDeploy: PropTypes.any,
  githubLink: PropTypes.string.isRequired,
  githubRevision: PropTypes.string.isRequired,
  creationTimestamp: PropTypes.string.isRequired,
}

export default DeployOverTime
