import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Modal } from '@wework/dieter-ui'
import PropTypes from 'prop-types'
import './cron-job-statuses.css'
import { Table } from '@wework/dieter-ui'
import {
  GET_CRON_JOBS_DETAILS as query,
  CRONJOB_RESTART as mutation,
  CronJobWithJobHistory,
  GetAppDetailsVariables,
  GetCronJobsDetailsQueryResult,
  CronjobRestartArgs,
  CronjobRestartResult,
} from '../../graphql/appDetails'
import { getGraphQLErrorMessage } from '../../graphql/helpers'
import { useEnv } from '../../components/Contexts/EnvironmentContext'
import { useAuth } from '../../components/Contexts/AuthContext'
import { useEscalation } from '../../components/Contexts/EscalateContext'
import {
  MouseOverEventInfo,
  buildMouseOverHandler,
  mouseoverTargetAttribute,
} from '../../mouseover'
import ElkBuildQueryLink from '../../components/ElkBuildQueryLink/ElkBuildQueryLink'
import ElkBuildCronJobssLinkView from '../../components/ElkBuildQueryLink/ElkBuildCronJobsLinkView'
import NoData from '../../components/NoData/NoData'
import CheckboxModal from './checkbox-modal-component'
import NavProps from './nav-props'

interface CronJobStatusesProps {
  nav: NavProps
}

export const CronJobStatuses: React.FunctionComponent<React.PropsWithChildren<
  CronJobStatusesProps
>> = props => {
  const envContext = useEnv()
  const userPermissions = useAuth()
  const { loading, data } = useQuery<
    GetCronJobsDetailsQueryResult,
    GetAppDetailsVariables
  >(query, {
    variables: envContext,
    pollInterval: 60000,
  })
  const [mouseOverInfo, setMouseOverInfo] = useState<MouseOverEventInfo>()
  const handleMouseOver = buildMouseOverHandler(setMouseOverInfo)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [restartError, setRestartError] = useState<string>('')
  const [cronjobRestart, { loading: mutationLoading }] = useMutation<
    CronjobRestartResult,
    CronjobRestartArgs
  >(mutation)
  const { requireEscalationForCallback } = useEscalation()

  if (loading) return <React.Fragment></React.Fragment>

  const jobs = data?.getCronJobsDetails || ([] as CronJobWithJobHistory[])
  const sortedJobs = jobs.slice().sort((x, y) => x.name.localeCompare(y.name))

  const restartCronjobCallback = async (jobNames: string[]) => {
    if (jobNames.length < 1) {
      setModalOpen(false)
      return
    }

    const jobName = jobNames[0]
    try {
      const res = await cronjobRestart({
        variables: { ...envContext, jobName },
      })
      if (res.data && res.data.runJob.status !== 'success') {
        throw new Error(
          `CronjobRestart responded with status: ${res.data.runJob.status}`,
        )
      }
      setModalOpen(false)
      setRestartError('')
      return
    } catch (err) {
      setRestartError(getGraphQLErrorMessage(err))
    }
  }

  if (!sortedJobs || sortedJobs.length === 0) {
    return (
      <div className="a-d-v-box" id={props.nav.id}>
        <p className="p box-header">
          <span className="details-section-title">{props.nav.title}</span>
        </p>
        <NoData>No cronjobs deployed for this app</NoData>
      </div>
    )
  }

  return (
    <div className="a-d-v-box" id={props.nav.id}>
      <p className="p box-header">
        <span className="details-section-title">{props.nav.title}</span>
        <span className="details-section-actions">
          {userPermissions.hasWriteAccess && (
            <React.Fragment>
              <CheckboxModal
                defaultCheckedValue={false}
                fieldNames={sortedJobs?.map(x => x.name) || []}
                error={restartError}
                open={modalOpen}
                setOpen={requireEscalationForCallback(setModalOpen)}
                trigger={<span className="detail-action-button">Restart</span>}
                submitCallback={restartCronjobCallback}
                disableForm={mutationLoading}
                title="Cron Job Restart"
                subtitle="Select cron job to be restarted:"
                radio={true}
                warning="Restarted jobs may take up to 60s to appear"
              />
              <span className="detail-action-button divider" />
            </React.Fragment>
          )}
          <Modal
            trigger={<span className="detail-action-button">Get Logs</span>}
            content={
              <ElkBuildQueryLink>
                {queryProps => (
                  <ElkBuildCronJobssLinkView
                    cronJobs={sortedJobs || []}
                    {...queryProps}
                  />
                )}
              </ElkBuildQueryLink>
            }
            header={'Build Elk Link from CronJobs'}
          />
        </span>
      </p>
      <div>
        {sortedJobs.map((job, idx) => {
          return (
            <Table
              className="cronjobs"
              key={`${job.name}-${idx}`}
              singleLine
              sortable
              striped
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={8}>
                    <div
                      data-index={idx}
                      onMouseOver={handleMouseOver}
                      onMouseOut={handleMouseOver}
                    >
                      <span {...{ [mouseoverTargetAttribute]: 'true' }}>
                        Name ({job.name})
                      </span>
                    </div>
                    {mouseOverInfo?.elemIdx !== idx ? (
                      ''
                    ) : (
                      <div
                        className="mouseover-container"
                        style={{
                          left: `${mouseOverInfo?.positionX}px`,
                        }}
                      >
                        <div>Schedule: {job.schedule}</div>
                        <div>Age: {job.age}</div>
                        <div>Last Scheduled: {job.lastScheduled}</div>
                        <div>Currently Running: {`${job.isActive}`}</div>
                        <div>Is Suspended: {`${job.isSuspended}`}</div>
                      </div>
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>Start Time</Table.HeaderCell>
                  <Table.HeaderCell>Completion Time</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body className="cronjobs">
                {
                  // copying scheduled jobs before reverse to avoid modifying state
                  [...job.scheduledJobs].reverse().map((s, i) => (
                    <Table.Row className="job-instance" key={i}>
                      <Table.Cell width={5}>
                        <span>
                          {s.name} {s.isManual ? '(manually triggered)' : ''}
                        </span>
                      </Table.Cell>
                      <Table.Cell>{s.startTime}</Table.Cell>
                      <Table.Cell>
                        {s.completionTime ? (
                          s.completionTime
                        ) : (
                          <span className={`job-state ${s.state}`}>
                            {s.state}
                          </span>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))
                }
              </Table.Body>
            </Table>
          )
        })}
      </div>
    </div>
  )
}

CronJobStatuses.propTypes = {
  nav: PropTypes.any.isRequired,
}

export default CronJobStatuses
