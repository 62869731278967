import React, { MouseEventHandler } from 'react'

export interface MouseOverEventInfo {
  elemIdx: number
  positionX: number
}

export const mouseoverTargetAttribute = 'data-mouseover-target'

export const buildMouseOverHandler = (
  setMouseOverInfo: React.Dispatch<
    React.SetStateAction<MouseOverEventInfo | undefined>
  >,
): MouseEventHandler<HTMLElement> => {
  return e => {
    let target = e.target as HTMLElement
    if (e.type === 'mouseout') {
      setMouseOverInfo(undefined)
      return
    }
    let idx = target.getAttribute('data-index')
    if (!idx) {
      idx = target.parentElement?.getAttribute('data-index') || ''
    } else {
      target =
        target.querySelector<HTMLElement>(`[${mouseoverTargetAttribute}]`) ||
        target
    }
    if (!idx) return

    setMouseOverInfo({
      elemIdx: parseInt(idx, 10),
      positionX: Math.round(
        target.getBoundingClientRect().left +
          target.offsetWidth -
          (target.parentElement?.getBoundingClientRect().left || 0),
      ),
    })
  }
}
