import { gql } from '@apollo/client'
import { SupportedClusters } from './statuses'

export interface GetAppDetailsQueryResult {
  getAppDetails: AppDetails
}

export interface UserPermissions {
  hasWriteAccess: boolean
  isEscalated: boolean
}

interface WeAPMDashboard {
  serviceName: string
  elkLink: string
  grafanaLink: string
}
interface UserDefinedDashboard {
  name: string
  link: string
}

export interface Dashboards {
  trafficGatewayLink: string
  weapm: WeAPMDashboard[]
  userDefined: UserDefinedDashboard[]
}

export interface AppDetails {
  deploymentInfo: AppDeploymentInfo
  services: AppService[]
  userPermissions: UserPermissions
  dashboards: Dashboards
}

interface PodInstance {
  name: string
  age: string
  instances: string
  restarts: number
  status: string
  version: string
}

export interface AppService {
  name: string
  podList: PodInstance[]
  monitor: AppMonitor
}

export interface AppMonitor {
  alertDestinations: AlertDestinations
  alerts: Alert[]
}

export interface AlertDestinations {
  slackWebhookUrl: string
  pagerdutyServiceKey: string
}

export interface Alert {
  name: string
  type: string
  expr: string
  severity: string
  description: string
  summary: string
  runbookUrl: string
}

export interface AppDeployment {
  creationTimestamp: string
  isActive: boolean
  githubRevision: string
  githubLink: string
}

export interface AppDeploymentInfo {
  activeDeployment: AppDeployment
  deploymentList: AppDeployment[]
  conciergeTeamId: string
  conciergeTeamLink: string
}

export interface GetAppDetailsVariables {
  appName: string
  namespace: string
  cluster: SupportedClusters
}

export const GET_APP_DETAILS = gql`
  query getAppDetails(
    $appName: String!
    $namespace: String!
    $cluster: String!
  ) {
    getAppDetails(appName: $appName, namespace: $namespace, cluster: $cluster) {
      deploymentInfo {
        activeDeployment {
          creationTimestamp
          isActive
          githubRevision
          githubLink
        }
        deploymentList {
          creationTimestamp
          isActive
          githubRevision
          githubLink
        }
        conciergeTeamId
        conciergeTeamLink
      }
      services {
        name
        podList {
          name
          age
          instances
          restarts
          status
          version
        }
        monitor {
          alertDestinations {
            slackWebhookUrl
            pagerdutyServiceKey
          }
          alerts {
            name
            type
            expr
            severity
            description
            summary
            runbookUrl
          }
        }
      }
      userPermissions {
        hasWriteAccess
        isEscalated
      }
      dashboards {
        trafficGatewayLink
        weapm {
          serviceName
          elkLink
          grafanaLink
        }
        userDefined {
          name
          link
        }
      }
    }
  }
`

export enum JobState {
  Failed = 'failed',
  Success = 'success',
  Active = 'active',
  Unknown = 'unknown',
}

export interface ScheduledJob {
  name: string
  state: JobState
  startTime: string
  completionTime: string | null
  isManual: boolean
}

export interface CronJobWithJobHistory {
  name: string
  schedule: string
  isSuspended: boolean
  isActive: boolean
  age: string
  lastScheduled: string
  scheduledJobs: ScheduledJob[]
}

export interface GetCronJobsDetailsQueryResult {
  getCronJobsDetails: CronJobWithJobHistory[]
}

export const GET_CRON_JOBS_DETAILS = gql`
  query getCronJobsDetails(
    $appName: String!
    $namespace: String!
    $cluster: String!
  ) {
    getCronJobsDetails(
      appName: $appName
      namespace: $namespace
      cluster: $cluster
    ) {
      name
      schedule
      isSuspended
      isActive
      age
      lastScheduled
      scheduledJobs {
        name
        state
        startTime
        completionTime
      }
    }
  }
`

export interface InstancesRestartResult {
  instancesRestart: {
    status: string
  }
}

export interface InstancesRestartArgs {
  cluster: string
  namespace: string
  appName: string
  serviceNames: string[]
}

export const INSTANCES_RESTART = gql`
  mutation instancesRestart(
    $cluster: String!
    $namespace: String!
    $appName: String!
    $serviceNames: [String!]!
  ) {
    instancesRestart(
      cluster: $cluster
      namespace: $namespace
      appName: $appName
      serviceNames: $serviceNames
    ) {
      status
    }
  }
`

interface BaseEnvArgs {
  cluster: string
  namespace: string
  appName: string
}

export type ListEnvVariablesArgs = BaseEnvArgs

export interface ListEnvVariablesQueryResult {
  listEnvVariables: {
    keys: string[]
  }
}

export interface ReadEnvVariableArgs extends BaseEnvArgs {
  key: string
}

export interface ReadEnvVariableResult {
  readEnvVariable: {
    key: string
    value: string
  }
}

export interface WriteEnvVariableArgs extends BaseEnvArgs {
  key: string
  value: string
}

export interface WriteEnvVariableResult {
  writeEnvVariable: {
    key: string
    value: string
  }
}

export interface DeleteEnvVariableArgs extends BaseEnvArgs {
  key: string
}

export const LIST_ENV_VARIABLES = gql`
  query listEnvVariables(
    $cluster: String!
    $namespace: String!
    $appName: String!
  ) {
    listEnvVariables(
      cluster: $cluster
      namespace: $namespace
      appName: $appName
    ) {
      keys
    }
  }
`

export const READ_ENV_VARIABLE = gql`
  query readEnvVariable(
    $cluster: String!
    $namespace: String!
    $appName: String!
    $key: String!
  ) {
    readEnvVariable(
      cluster: $cluster
      namespace: $namespace
      appName: $appName
      key: $key
    ) {
      key
      value
    }
  }
`

export const WRITE_ENV_VARIABLE = gql`
  mutation writeEnvVariable(
    $cluster: String!
    $namespace: String!
    $appName: String!
    $key: String!
    $value: String!
  ) {
    writeEnvVariable(
      cluster: $cluster
      namespace: $namespace
      appName: $appName
      key: $key
      value: $value
    ) {
      key
      value
    }
  }
`

export const DELETE_ENV_VARIABLE = gql`
  mutation deleteEnvVariable(
    $cluster: String!
    $namespace: String!
    $appName: String!
    $key: String!
  ) {
    deleteEnvVariable(
      cluster: $cluster
      namespace: $namespace
      appName: $appName
      key: $key
    )
  }
`

export interface CronjobRestartArgs {
  cluster: string
  namespace: string
  appName: string
  jobName: string
}

export interface CronjobRestartResult {
  runJob: RunJob
}

export interface RunJob {
  status: string
}

export const CRONJOB_RESTART = gql`
  mutation runJob(
    $cluster: String!
    $namespace: String!
    $appName: String!
    $jobName: String!
  ) {
    runJob(
      cluster: $cluster
      namespace: $namespace
      appName: $appName
      jobName: $jobName
    ) {
      status
    }
  }
`
/**
 * {SupportedScopeTypesForElkQueries} work mostly as expected except that
 * for cron jobs, we have to use `Service` type. This is due to logs using
 * the same label for deploy types regardless of whether it's a service or
 * a cron job. For batch jobs (jobs triggered by cron jobs), we use the {Job}
 * type.
 */
export enum SupportedScopeTypesForElkQueries {
  Service = 'service',
  Job = 'job',
  Pod = 'pod',
}

export interface BuildElkQueryLinkArgs {
  cluster: SupportedClusters
  namespace: string
  scope: SupportedScopeTypesForElkQueries
  scopeValues: string[]
  startTime: string
  endTime?: string // if falsey, then endTime is now()
}

export interface BuildElkQueryLinkResult {
  buildElkQueryLink: {
    link: string
  }
}

export const BUILD_ELK_QUERY_LINK = gql`
  query buildElkQueryLink(
    $cluster: String!
    $namespace: String!
    $scope: String!
    $scopeValues: [String!]!
    $startTime: String!
    $endTime: String
  ) {
    buildElkQueryLink(
      cluster: $cluster
      namespace: $namespace
      scope: $scope
      scopeValues: $scopeValues
      startTime: $startTime
      endTime: $endTime
    ) {
      link
    }
  }
`
