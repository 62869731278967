import React, { useState, useEffect } from 'react'
// @ts-ignore
import { useOktaAuth } from '@okta/okta-react'

const LoggedInOkta = (): JSX.Element | null => {
  const { authState, authService } = useOktaAuth()
  const [userInfo, setUserInfo] = useState<any | null>(null)

  useEffect(() => {
    if (!authState.isAuthenticated) {
      setUserInfo(null)
    } else {
      // @ts-ignore
      authService.getUser().then(info => {
        setUserInfo(info)
      })
    }
  }, [authState, authService])

  return (
    <div>
      {userInfo && (
        <div>
          {/* @ts-ignore */}
          <p>Welcome, {userInfo.name}</p>
        </div>
      )}
    </div>
  )
}

export default LoggedInOkta
