import React from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { DatePicker } from '@wework/dieter-ui'
import { DayModifiers } from 'react-day-picker'

interface CalendarProps {
  handleDateChange: (data: Date) => void
  currentDate: Date
}

export const Calendar: React.FunctionComponent<React.PropsWithChildren<
  CalendarProps
>> = props => {
  const [state, setState] = useState<{ selectedDay: Date }>({
    selectedDay: props.currentDate,
  })

  const handleDayClick = (day: Date, modifiers: DayModifiers) => {
    if (modifiers.disabled) {
      return
    }

    setState({
      selectedDay: modifiers.selected ? state.selectedDay : day,
    })
    props.handleDateChange(day)
  }

  const disabledDays = {
    after: new Date(),
  }
  return (
    <div>
      <DatePicker
        showOutsideDays
        selectedDays={state.selectedDay}
        disabledDays={disabledDays}
        onDayClick={handleDayClick}
      />
    </div>
  )
}

Calendar.propTypes = {
  handleDateChange: PropTypes.func.isRequired,
  currentDate: PropTypes.instanceOf(Date).isRequired,
}

export default Calendar
