import React from 'react'
import PropTypes from 'prop-types'
import './EnvVariables.css'
import { Table, Image, Icon } from '@wework/dieter-ui'
import { Grid } from 'semantic-ui-react'
import { useAuth } from '../Contexts/AuthContext'
import { useEscalation } from '../Contexts/EscalateContext'
import NavProps, {
  sidebarLinksIds,
} from '../../pages/app-detail-view/nav-props'
import NoData from '../NoData/NoData'
import DeleteEnvVariableModal from './DeleteEnvVariableModal'
import EnvVariablesViewEditor from './EnvVariablesViewEditor'
import eyeSvg from './greyEye.svg'
import { StateProps } from './EnvVariables'

export interface EnvVariablesProps {
  readEnvVarCallback: (key: string) => Promise<void>
  writeEnvVarCallback: (key: string, value: string) => Promise<void>
  deleteEnvVarCallback: (key: string, value: string) => Promise<void>
  variablesList: StateProps
  nav: NavProps
}

export const EnvVariablesView: React.FunctionComponent<React.PropsWithChildren<
  EnvVariablesProps
>> = props => {
  const {
    readEnvVarCallback,
    writeEnvVarCallback,
    deleteEnvVarCallback,
    variablesList,
  } = props
  const { requireEscalationForCallback, setEscalationOpen } = useEscalation()
  const { isEscalated, hasWriteAccess } = useAuth()

  const keyList = Object.keys(props.variablesList)
  const halfIdx = Math.ceil(keyList.length / 2)
  const keysFirstHalf = keyList.slice(0, halfIdx)
  const keysSecondHalf = keyList.slice(halfIdx)

  if (!hasWriteAccess) {
    return (
      <div className="a-d-v-box environment-variables-view" id={props.nav.id}>
        <p className="p box-header">
          <span className="details-section-title">{props.nav.title}</span>
        </p>
        <div>
          <Grid columns={2}>
            <Grid.Column width={16}>
              <NoData>
                Environment variables are viewable only by the team that
                maintains this app.
              </NoData>
            </Grid.Column>
          </Grid>
        </div>
      </div>
    )
  }

  return (
    <div className="a-d-v-box environment-variables-view" id={props.nav.id}>
      <p className="p box-header">
        <span className="details-section-title">{props.nav.title}</span>
        {
          <EnvVariablesViewEditor
            edit={false}
            writeEnvVarCallback={writeEnvVarCallback}
            trigger={<span className="detail-action-button">Add Variable</span>}
          />
        }
      </p>
      <div>
        <Grid columns={2}>
          {!isEscalated ? (
            <Grid.Column width={16}>
              <NoData>
                You can only view environment variables when escalated. Click{' '}
                <span
                  className="fake-link"
                  onClick={() => setEscalationOpen(true)}
                >
                  here
                </span>{' '}
                to escalate.
              </NoData>
            </Grid.Column>
          ) : (
            [keysFirstHalf, keysSecondHalf].map((keys, outerIdx) => {
              return (
                <Grid.Column key={`outer-${outerIdx}`} width={8}>
                  <Table singleLine sortable striped fixed>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={7}>Key</Table.HeaderCell>
                        <Table.HeaderCell width={9}>Value</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {keys.map((key, idx) => {
                        return (
                          <Table.Row
                            key={`${key}-${idx}`}
                            className={
                              variablesList[key].loading ? 'loading' : ''
                            }
                          >
                            <Table.Cell>
                              <EnvVariablesViewEditor
                                edit={true}
                                keyName={key}
                                writeEnvVarCallback={writeEnvVarCallback}
                              />

                              <span className="keyName">{key}</span>
                            </Table.Cell>
                            <Table.Cell>
                              <Grid>
                                <Grid.Column width={14} className="value">
                                  {variablesList[key].value ? (
                                    <React.Fragment>
                                      <span>{variablesList[key].value}</span>
                                      {variablesList[key].showRestartWarning ? (
                                        <div className="restart-message">
                                          Please{' '}
                                          <a
                                            href={`#${sidebarLinksIds.INSTANCES_AND_STATUSES}`}
                                          >
                                            restart your instances
                                          </a>{' '}
                                          to apply these changes
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    <span
                                      onClick={requireEscalationForCallback(
                                        () => void readEnvVarCallback(key),
                                      )}
                                    >
                                      <Image className="eye" src={eyeSvg} />
                                    </span>
                                  )}
                                </Grid.Column>
                                <Grid.Column
                                  width={2}
                                  className="remove-env-var"
                                >
                                  <DeleteEnvVariableModal
                                    variableName={key}
                                    trigger={<Icon name="x" color="red" />}
                                    onSubmit={(): Promise<void> => {
                                      return deleteEnvVarCallback(
                                        key,
                                        variablesList[key].value,
                                      )
                                    }}
                                  />
                                </Grid.Column>
                              </Grid>
                            </Table.Cell>
                          </Table.Row>
                        )
                      })}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              )
            })
          )}
        </Grid>
      </div>
    </div>
  )
}

EnvVariablesView.propTypes = {
  readEnvVarCallback: PropTypes.func.isRequired,
  writeEnvVarCallback: PropTypes.func.isRequired,
  deleteEnvVarCallback: PropTypes.func.isRequired,
  variablesList: PropTypes.any.isRequired,
  nav: PropTypes.any.isRequired,
}
export default EnvVariablesView
