import React from 'react'
import PropTypes from 'prop-types'
import { Table, Card } from '@wework/dieter-ui'
import NavProps from '../../pages/app-detail-view/nav-props'
import { Alert, AppService } from '../../graphql/appDetails'
import './PrometheusAlertsView.css'

export interface PrometheusAlertsProps {
  nav: NavProps
  services: AppService[] | undefined
}

// PrometheusAlertsView shows the alert *rules* that services have in Prometheus, allowing users to see their alerts rules without having to dig into their configurations.

export const PrometheusAlertsView: React.FunctionComponent<React.PropsWithChildren<
  PrometheusAlertsProps
>> = props => {
  const { nav, services } = props

  function displayService(service: AppService) {
    return (
      <React.Fragment>
        <Table>
          <Table.Header>
            <Table.HeaderCell id="service-name-header" className="service-name">
              Service Name ({service.name})
            </Table.HeaderCell>
          </Table.Header>
          {service.monitor !== null ? (
            <Card.Group>
              {service.monitor.alerts.map(alert => displayAlertsCard(alert))}
            </Card.Group>
          ) : (
            <Table.Row>
              <Table.Cell>This service has no alerts</Table.Cell>
            </Table.Row>
          )}
        </Table>
      </React.Fragment>
    )
  }

  function displayAlertsCard(alert: Alert) {
    const extra = (
      <div>
        <p className="text-expand">
          {<p className="pointer">Expression:</p>}
          {alert.expr}
        </p>

        {alert.runbookUrl && (
          <p className="text-expand">
            {<p className="pointer">RunBook:</p>}
            {alert.runbookUrl}
          </p>
        )}
      </div>
    )
    return (
      <Card
        className="card-resize"
        raised
        header={alert.name}
        meta={alert.severity}
        description={alert.description}
        extra={extra}
        key={`alert-${alert.name}`}
      />
    )
  }

  return (
    <div className="a-d-v-box environment-variables-view" id={nav.id}>
      <p className="p box-header">
        <span className="details-section-title">{nav.title}</span>
      </p>
      {services?.map(service => displayService(service))}
    </div>
  )
}

PrometheusAlertsView.propTypes = {
  nav: PropTypes.any,
  services: PropTypes.any,
}
export default PrometheusAlertsView
