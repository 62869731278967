import React, { ChangeEvent, MouseEvent, SyntheticEvent, useState } from 'react'
import PropTypes from 'prop-types'
import '../../pages/app-detail-view/app-historical-status.css'
import { Button, Form, Popup, Input, FormFieldProps } from '@wework/dieter-ui'
import { FaUndo } from 'react-icons/fa'
import Calendar from './Calendar'

export interface CalendarWrapperProps {
  setOffset: React.Dispatch<React.SetStateAction<string | undefined>>
  offset: string | undefined
  rangeInHours: string
  startTime: string
  endTime: string
  elkLink: string
}

export const CalendarWrapper: React.FunctionComponent<React.PropsWithChildren<
  CalendarWrapperProps
>> = props => {
  const [day, setDay] = useState<Date>(new Date())
  const [time, setTime] = useState<string[]>()
  const [error, setError] = useState<boolean>(false)
  const rangeInHours = Number(props.rangeInHours.slice(0, -1))

  function handleDateChange(data: Date): void {
    setDay(data)
  }

  function handleClick(_event: MouseEvent<HTMLButtonElement>): void {
    props.setOffset(undefined)
  }

  function handleTimeChange<T = Element>(
    _event: ChangeEvent<T>,
    changeProps?: FormFieldProps,
  ): void {
    const value = (changeProps?.value as string) || ''
    if (/^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(value)) {
      setError(false)
      setTime(value.split(':'))
    } else setError(true)
  }

  function handlePopupClose(_event: SyntheticEvent<HTMLElement>): void {
    if (error) return
    if (time) {
      day.setHours(parseInt(time[0], 10) + rangeInHours, parseInt(time[1], 10))
    }
    props.setOffset(
      Math.round((Date.now() - day.getTime()) / 1000).toString() + 's',
    )
  }

  const timeResolutionLabel = () => {
    const startDate = new Date(props.startTime)
    const endDate = new Date(props.endTime)
    const options1: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      timeZoneName: 'short',
    }
    const options2: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    }
    return (
      <span className="historical-status-timestamp">
        Data shown{' '}
        <u>
          <a href={props.elkLink} target="_blank" rel="noopener noreferrer">
            from {startDate.toLocaleString(undefined, options1)} to{' '}
            {endDate.toLocaleString(undefined, options2)}
          </a>
        </u>
        , in 5m intervals
      </span>
    )
  }

  return (
    <div className="calendar-wrapper">
      <Popup
        className="resize"
        trigger={
          <Button size="mini" className="status-button">
            Edit Status Window
          </Button>
        }
        on="click"
        onClose={handlePopupClose}
        content={
          <Form onSubmit={handlePopupClose}>
            <Form.Field
              outline
              label="Starting Time"
              id="form-input-control-error-time"
              control={Input}
              className="historical-status-timepicker"
              onChange={handleTimeChange}
              placeholder="Please select a starting time"
              error={error}
              hint={
                error
                  ? 'Valid times are 24 hour military time, e.g 02:34 or 21:21'
                  : ' '
              }
            />
            <Calendar currentDate={day} handleDateChange={handleDateChange} />
            {day ? 'Date: ' + day.toLocaleDateString() : ' '}
          </Form>
        }
      />
      {props.offset ? (
        <span className="status-reset" onClick={handleClick}>
          <FaUndo /> Reset Status Window
        </span>
      ) : null}
      {timeResolutionLabel()}
    </div>
  )
}

CalendarWrapper.propTypes = {
  setOffset: PropTypes.func.isRequired,
  offset: PropTypes.string.isRequired,
  rangeInHours: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  elkLink: PropTypes.string.isRequired,
}
