import { gql } from '@apollo/client'

export enum SupportedClusters {
  DevUsWest = 'wek8s-dev-us-west',
  ProdUsEast = 'wek8s-prod-us-east',
  NoCluster = '',
}

export enum ServiceStatus {
  DOWN = 'down',
  UP = 'up',
  ERROR = 'error',
  UNKNOWN = 'unknown',
  DEGRADED = 'degraded',
}

export interface GetAppStatusesQueryResult {
  getStatuses: AppStatus[]
}

export interface GetAppStatusesVariables {
  cluster: string
}

export interface AppStatus {
  id: string
  cluster: SupportedClusters
  namespace: string
  name: string
  status: ServiceStatus
  gitRepo?: string
  trafficGatewayLink?: string
  conciergeTeamId?: string
  conciergeTeamLink?: string
}

export const GET_STATUSES = gql`
  query getStatuses($cluster: String!) {
    getStatuses(cluster: $cluster) {
      id
      cluster
      namespace
      name
      status
      gitRepo
      trafficGatewayLink
      conciergeTeamId
      conciergeTeamLink
    }
  }
`

export interface GetAppHistoricalStatusesQueryResult {
  getAppHistoricalStatuses: {
    statuses: AppStatusAtTime[]
    startTime: string
    endTime: string
    elkLink: string
  }
}

export interface GetAppHistoricalStatusesVariables {
  cluster: string
  namespace: string
  appName: string
  range: string
  resolution: string
  offset?: string
}

export interface AppStatusAtTime {
  aggregateStatus: string
  timestamp: string
  serviceStatuses: ServiceFragment[]
}

export interface ServiceFragment {
  name: string
  status: ServiceStatus
  instances: string
}

export const GET_APP_HISTORICAL_STATUSES = gql`
  query getAppHistoricalStatuses(
    $cluster: String!
    $namespace: String!
    $appName: String!
    $range: String!
    $resolution: String!
    $offset: String
  ) {
    getAppHistoricalStatuses(
      cluster: $cluster
      namespace: $namespace
      appName: $appName
      range: $range
      resolution: $resolution
      offset: $offset
    ) {
      statuses {
        timestamp
        serviceStatuses {
          status
          name
          instances
        }
        aggregateStatus
      }
      startTime
      endTime
      elkLink
    }
  }
`
