import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Text,
  Dropdown,
  DropdownItemProps,
  DropdownProps,
} from '@wework/dieter-ui'
import './AppBarHeader.css'

interface AppBarHeaderProps {
  namespace: string[]
  defaultNS: string[]
  onChange: (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps,
  ) => void
}

export const AppBarHeader: React.FunctionComponent<React.PropsWithChildren<
  AppBarHeaderProps
>> = props => {
  const namespaces: DropdownItemProps[] = props.namespace.map(ns => {
    return { key: ns, text: ns, value: ns }
  })

  return (
    <Grid columns={6} className="app-bar-header">
      <Grid.Column width={4}>
        <Text secondary size="large">
          Application Name
        </Text>
      </Grid.Column>
      <Grid.Column width={4}>
        <Dropdown
          className="ui button app-bar-header-dropdown large text"
          onChange={props.onChange}
          options={namespaces}
          defaultValue={props.defaultNS}
          multiple
          search
          icon="caret down"
          text={props.defaultNS.length === 0 ? 'Namespace' : ''}
        />
      </Grid.Column>
      <Grid.Column>
        <Text secondary size="large">
          Owner
        </Text>
      </Grid.Column>
      <Grid.Column>
        <Text secondary size="large">
          Status
        </Text>
      </Grid.Column>
      <Grid.Column>
        <Grid columns={3}>
          <Grid.Column>
            <Text secondary size="large">
              Github
            </Text>
          </Grid.Column>
        </Grid>
      </Grid.Column>
    </Grid>
  )
}

AppBarHeader.propTypes = {
  namespace: PropTypes.array.isRequired,
  defaultNS: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default AppBarHeader
