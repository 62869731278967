import React from 'react'
import { Image, Menu } from '@wework/dieter-ui'
import { Link } from 'react-router-dom'
import WeWork from '../wework.svg'
import LoggedInOkta from './LoggedInUserOkta'
import EscalatedStatusBar from './Escalation/EscalatedStatusBar'

const TopBar = () => {
  return (
    <Menu className="wectl-menu" secondary>
      <Menu.Item header>
        <Link to="/">
          <Image src={WeWork} />
        </Link>
      </Menu.Item>
      <Menu.Item as="h3">WeCTL-UI</Menu.Item>
      <Menu.Item position="right">
        <EscalatedStatusBar />
      </Menu.Item>
      <Menu.Menu position="right">
        <Menu.Item as="h4">{<LoggedInOkta />}</Menu.Item>
      </Menu.Menu>
    </Menu>
  )
}

export default TopBar
