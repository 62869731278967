import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { initialize } from './analytics'
import { client } from './graphql/client'
import '@wework/dieter-ui/dist/index.css'
import 'focus-visible'

initialize(window.location.hostname)

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
