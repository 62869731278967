import { createContext, useContext } from 'react'
import { UserPermissions } from '../../graphql/appDetails'
import { GetAppDetailsVariables } from '../../graphql/appDetails'
import { SupportedClusters } from '../../../src/graphql/statuses'

export const AuthContext = createContext<UserPermissions>({
  hasWriteAccess: false,
  isEscalated: false,
})

export const useAuth = () => useContext(AuthContext)

export function escalationHelper(
  user: UserPermissions,
  envVars: GetAppDetailsVariables,
): UserPermissions {
  if (envVars.cluster === SupportedClusters.DevUsWest) {
    return { ...user, ...{ isEscalated: true } }
  }
  return user
}
