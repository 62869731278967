import { createContext, useContext } from 'react'

export interface Escalation {
  escalationOpen: boolean
  setEscalationOpen: Function
  requireEscalationForCallback: <E extends any[]>(
    cb: (...args: E) => void,
  ) => (...args: E) => void
}

export const EscalateContext = createContext<Escalation>({
  escalationOpen: false,
  setEscalationOpen: () => null,
  requireEscalationForCallback: (_e: () => void) => {
    return () => {
      return
    }
  },
})

export const useEscalation = () => useContext(EscalateContext)
