const weworkersEnv = 'wectl-ui.weworkers.io'
const localEnv = 'localhost'

export function getGaParams(
  hostname: string,
): [string] | [string, { debug: boolean }] {
  if (hostname === weworkersEnv) {
    return ['UA-161054325-3']
  }
  return ['none', { debug: false }] // update to debug == true to debug
}

export function getGraphQLEndpoint(hostname: string): string {
  switch (hostname) {
    case localEnv:
      return 'http://localhost:8080/production/graphql'
    case weworkersEnv:
      return 'https://wectl-bff.hydra.prod.wwrk.co/graphql'
    default:
      return `https://${window.location.hostname.replace(
        /^wectl-ui/,
        'wectl-bff',
      )}/graphql`
  }
}
