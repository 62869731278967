import PropTypes from 'prop-types'
import React from 'react'
// @ts-ignore
import { VelocityTransitionGroup } from 'velocity-react'
import _ from 'lodash'
import 'velocity-animate/velocity.ui'

interface TransitionProps {
  animation?: string
  stagger?: number
  duration: number
  display?: string
  visibility: string
  delay: number
}

interface AnimateGroupProps {
  enter?: TransitionProps
  leave?: TransitionProps
  easing?: [number, number, number, number]
  runOnMount?: boolean
  enterHideStyle?: {
    visibility?: string
  }
  enterShowStyle?: {
    visibility?: string
  }
}

const enterAnimationDefaults: TransitionProps = {
  animation: 'transition.slideUpBigIn',
  stagger: 50,
  duration: 200,
  visibility: 'visible',
  delay: 0,
}

const leaveAnimationDefaults: TransitionProps = {
  stagger: 50,
  duration: 200,
  visibility: 'visible',
  delay: 0,
}

const AnimateGroup: React.FunctionComponent<React.PropsWithChildren<
  AnimateGroupProps
>> = props => {
  const newProps = _.merge(
    {},
    {
      enter: enterAnimationDefaults,
      leave: leaveAnimationDefaults,
    },
    props,
  )

  return (
    <VelocityTransitionGroup {...newProps}>
      {props.children}
    </VelocityTransitionGroup>
  )
}

AnimateGroup.propTypes = {
  children: PropTypes.any,
}

AnimateGroup.defaultProps = {
  enter: enterAnimationDefaults,
  leave: leaveAnimationDefaults,
  easing: [0.4, 0.0, 0.2, 1],
  runOnMount: true,
  enterHideStyle: {
    visibility: 'visible',
  },
  enterShowStyle: {
    visibility: 'hidden',
  },
}

export default React.memo(AnimateGroup)
