import React, { useEffect } from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
// @ts-ignore
import { Security, LoginCallback } from '@okta/okta-react'
import { routes } from './routes'
import { isAuthenticated, addCallbackToExpiration } from './auth'
import { useTracking } from './analytics'

const renderedRoutes = routes.map(route => {
  return (
    <Route
      path={route.path}
      key={route.path}
      render={({ location }) => {
        return route.canBeViewedAsUnauthenticated || isAuthenticated() ? (
          //  @ts-ignore
          <route.page location={location} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: location } }}
          ></Redirect>
        )
      }}
    />
  )
})

const App: React.FunctionComponent = () => {
  const history = useHistory()
  useTracking()

  useEffect(() => {
    /**
     * redirect to login page, keep "from" as current location
     * so that once user logs in, they will go back to page
     * they were on
     */
    addCallbackToExpiration(() =>
      history.push('/login', { from: history.location }),
    )
  }, [history]) // run only on init

  return (
    <Security
      issuer="https://we.okta.com/oauth2/default"
      clientId="0oa3qh0g0egsz4E5W357"
      pkce={true}
      redirectUri={window.location.origin + '/implicit/callback'}
    >
      <Switch>
        <Route path="/implicit/callback" component={LoginCallback} />
        {renderedRoutes}
      </Switch>
    </Security>
  )
}

export default App
