import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Form, Grid } from '@wework/dieter-ui'
import {
  CronJobWithJobHistory,
  JobState,
  SupportedScopeTypesForElkQueries,
} from '../../graphql/appDetails'
import { ElkBuildQueryLinkViewProps } from './ElkBuildQueryLink'
import { renderButtonSection } from './SharedHelpers'
import './ElkBuildQueryLink.css'

export interface ElkBuildCronJobsLinkViewProps
  extends ElkBuildQueryLinkViewProps {
  cronJobs: CronJobWithJobHistory[]
}

interface JobGroupProps {
  name: string
  selected: boolean
  startTime: string
  endTime: string | null
}

interface IndividualJobProps extends JobGroupProps {
  state: JobState
}

interface CheckboxMap {
  [key: string]: JobGroupProps | IndividualJobProps
}

const defaultStartTime = '3h'

function buildJobGroupScopeValues(
  cronJobs: CronJobWithJobHistory[],
): CheckboxMap {
  return cronJobs.reduce<CheckboxMap>((acc, val) => {
    const startTime =
      val.scheduledJobs.length !== 0
        ? val.scheduledJobs[0].startTime // backend returns jobs
        : defaultStartTime

    const newValues: JobGroupProps = {
      name: val.name,
      selected: false,
      startTime,
      endTime: null, // ignore endTime for services
    }

    acc[val.name] = newValues
    return acc
  }, {} as CheckboxMap)
}

function buildIndividualJobScopeValues(
  cronJobs: CronJobWithJobHistory[],
): CheckboxMap {
  const scheduledJobs = cronJobs.reduce<IndividualJobProps[]>((acc, val) => {
    val.scheduledJobs.forEach(x => {
      acc.push({
        name: x.name,
        selected: false,
        startTime: x.startTime,
        endTime: x.completionTime,
        // JobState.Success isn't a real state, but setting it here, because showing the completion time
        // will be a little TMI in this smaller view (normally, success is determined by 'completionTime').
        state: x.completionTime ? JobState.Success : x.state,
      })
    })
    return acc
  }, [])

  scheduledJobs.sort(
    // sort jobs so the latest scheduled show at the top of the list
    (x, y) => Date.parse(y.startTime) - Date.parse(x.startTime),
  )

  return scheduledJobs.reduce<CheckboxMap>((acc, val) => {
    acc[val.name] = val
    return acc
  }, {})
}

function initFormState(
  scope: SupportedScopeTypesForElkQueries,
  cronJobs: CronJobWithJobHistory[],
): { scope: SupportedScopeTypesForElkQueries; scopeValuesMap: CheckboxMap } {
  const funcToProcess =
    scope === SupportedScopeTypesForElkQueries.Job
      ? buildIndividualJobScopeValues
      : buildJobGroupScopeValues

  return {
    scope,
    scopeValuesMap: funcToProcess(cronJobs),
  }
}

const ElkBuildCronJobsLinkView: React.FunctionComponent<ElkBuildCronJobsLinkViewProps> = props => {
  const { cronJobs, error, elkLink, updateElkLink, loading } = props
  const [formState, setFormState] = useState<{
    scope: SupportedScopeTypesForElkQueries
    scopeValuesMap: CheckboxMap
  }>(initFormState(SupportedScopeTypesForElkQueries.Service, cronJobs))
  const { scope, scopeValuesMap } = formState

  const changeFormStateCallback = () => {
    const scopeValues = Object.keys(formState.scopeValuesMap).filter(
      x => formState.scopeValuesMap[x].selected,
    )
    let startDate: Date | null = null
    let endDate: Date | null = null

    let useNowForEndTime = false

    for (const name of scopeValues) {
      const entry = formState.scopeValuesMap[name]
      const newStartDate = entry.startTime
        ? new Date(Date.parse(entry.startTime))
        : null
      if (newStartDate && (!startDate || startDate > newStartDate)) {
        startDate = newStartDate
      }

      if (useNowForEndTime) continue

      const newEndDate = entry.endTime
        ? new Date(Date.parse(entry.endTime))
        : null

      if (newEndDate === null) {
        // if we parse a job that hasn't completed yet
        // just make the endTime 'now' (undefined means 'now')
        endDate = null
        useNowForEndTime = true
        continue
      }

      if (!endDate || endDate < newEndDate) {
        endDate = newEndDate
      }
    }

    const startTime = startDate ? startDate.toISOString() : defaultStartTime

    const endTime =
      // eslint-disable-next-line id-blacklist
      endDate && !useNowForEndTime ? endDate.toISOString() : undefined

    void updateElkLink(formState.scope, scopeValues, startTime, endTime)
  }

  const updateCheckedSelection = (key: string, selected: boolean) => {
    const oldEntry = scopeValuesMap[key]
    oldEntry.selected = selected
    const newValue = {
      ...scopeValuesMap,
      [key]: { ...oldEntry },
    }
    setFormState({
      scope,
      scopeValuesMap: newValue,
    })
  }

  useEffect(changeFormStateCallback, [formState])

  return (
    <React.Fragment>
      <Form className="cronjobs-elk-link-view">
        <div className="scope-type">
          <p>Query by:</p>
          <Grid columns={2}>
            <Grid.Column>
              <Checkbox
                key={SupportedScopeTypesForElkQueries.Service}
                name="scope"
                label="job group"
                onChange={() =>
                  setFormState(
                    initFormState(
                      SupportedScopeTypesForElkQueries.Service,
                      cronJobs,
                    ),
                  )
                }
                className={`modal-checkbox modal-checkbox-${SupportedScopeTypesForElkQueries.Service}`}
                checked={scope === SupportedScopeTypesForElkQueries.Service}
                type="radio"
                radio={true}
              />
            </Grid.Column>
            <Grid.Column>
              <Checkbox
                key={SupportedScopeTypesForElkQueries.Job}
                name="scope"
                label={SupportedScopeTypesForElkQueries.Job}
                onChange={() =>
                  setFormState(
                    initFormState(
                      SupportedScopeTypesForElkQueries.Job,
                      cronJobs,
                    ),
                  )
                }
                className={`modal-checkbox modal-checkbox-${SupportedScopeTypesForElkQueries.Job}`}
                checked={scope === SupportedScopeTypesForElkQueries.Job}
                type="radio"
                radio={true}
              />
            </Grid.Column>
          </Grid>
        </div>
        <div>
          <p>Select values to include:</p>
          <Grid>
            {Object.keys(scopeValuesMap).map(name => {
              // typecasting avoids compiler complaints when accessing `.state`
              const entry = scopeValuesMap[name] as IndividualJobProps
              return (
                <Grid.Row key={name} className="cronjob-entry">
                  <Grid.Column width={12}>
                    <Checkbox
                      key={name}
                      name={name}
                      label={name}
                      value={name}
                      onChange={(_e, value) => {
                        updateCheckedSelection(name, !!value.checked)
                      }}
                      checked={entry.selected}
                      className="modal-checkbox"
                    />
                  </Grid.Column>
                  {entry.state && (
                    <Grid.Column width={4}>
                      <span className={`job-state ${entry.state}`}>
                        {entry.state}
                      </span>
                    </Grid.Column>
                  )}
                </Grid.Row>
              )
            })}
          </Grid>
        </div>
        {renderButtonSection(loading, elkLink)}
      </Form>
      {error && <span className="error">{error}</span>}
    </React.Fragment>
  )
}

ElkBuildCronJobsLinkView.propTypes = {
  cronJobs: PropTypes.array.isRequired,
  error: PropTypes.string.isRequired,
  elkLink: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  updateElkLink: PropTypes.any.isRequired,
}

export default ElkBuildCronJobsLinkView
