import React, { useState, ChangeEvent, MouseEvent } from 'react'
import {
  Form,
  Popup,
  Input,
  Button,
  FormFieldProps,
  Image,
} from '@wework/dieter-ui'
import PropTypes from 'prop-types'
import { useEscalation } from '../Contexts/EscalateContext'
import './EnvVariables.css'
import keySvg from './key.svg'

export interface EnvVariablesViewEditorProps {
  edit: boolean
  keyName?: string
  trigger?: React.ReactNode
  writeEnvVarCallback: (key: string, value: string) => void
}

export const EnvVariablesViewEditor: React.FunctionComponent<React.PropsWithChildren<
  EnvVariablesViewEditorProps
>> = props => {
  const { edit, keyName, writeEnvVarCallback } = props
  const [newValue, setNewValue] = useState<string>('')
  const [envKey, setEnvKey] = useState<string>(keyName || '')
  const [popup, setPopup] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const { requireEscalationForCallback } = useEscalation()

  function handleValueOnChange<T = Element>(
    _event: ChangeEvent<T>,
    changeProps?: FormFieldProps,
  ): void {
    const value = (changeProps?.value as string) || ''
    setNewValue(value)
  }

  function handleKeyOnChange<T = Element>(
    _event: ChangeEvent<T>,
    changeProps?: FormFieldProps,
  ): void {
    const value = (changeProps?.value as string) || ''
    setEnvKey(value)
  }

  function handleSubmit(_event: MouseEvent<HTMLButtonElement>): void {
    if (!newValue || !envKey) {
      setError('Neither Key or Value can be blank')
    } else {
      writeEnvVarCallback(envKey, newValue)
      setPopup(false)
    }
  }

  return (
    <Popup
      trigger={
        props.trigger ? props.trigger : <Image className="key" src={keySvg} />
      }
      on="click"
      open={popup}
      onClose={() => setPopup(false)}
      onOpen={requireEscalationForCallback(() => setPopup(true))}
      position={'right center'}
      header={
        edit ? (
          <span>Edit your Variables</span>
        ) : (
          <span>Add a New Variable</span>
        )
      }
      content={
        <Form>
          <Form.Field
            outline
            required
            disabled={edit}
            label="Key"
            name="key-input"
            control={Input}
            onChange={handleKeyOnChange}
            defaultValue={edit ? keyName : ''}
          />
          <Form.Field
            outline
            required
            label="Value"
            name="value-input"
            control={Input}
            onChange={handleValueOnChange}
            placeholder="Enter your desired value here"
            hint={error ? error : ''}
          />
          <Button
            name="submit-button"
            className="env-edit-button"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Form>
      }
    ></Popup>
  )
}

EnvVariablesViewEditor.propTypes = {
  edit: PropTypes.bool.isRequired,
  keyName: PropTypes.string,
  writeEnvVarCallback: PropTypes.func.isRequired,
  trigger: PropTypes.any,
}
export default EnvVariablesViewEditor
