/* eslint-disable no-console */
import React, { MouseEvent, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from '@wework/dieter-ui'
import { useEnv } from '../Contexts/EnvironmentContext'
import { useEscalation } from '../Contexts/EscalateContext'
import { SupportedClusters } from '../../../src/graphql/statuses'

interface EscalateModalProps {
  conciergeLink: string
}

const EscalationModal: React.FunctionComponent<React.PropsWithChildren<
  EscalateModalProps
>> = props => {
  const { conciergeLink } = props
  const escalate = useEscalation()
  const env = useEnv()
  const [error, setError] = useState<string>('')

  function formatURL(): string {
    return conciergeLink
  }

  function handleClick(_event: MouseEvent<HTMLButtonElement>): void {
    if (env.cluster === SupportedClusters.DevUsWest) {
      setError(
        `App '${env.appName}' is in the development cluster and escalation is not required. How did you even get to this option?`,
      )
      return
    }
    window.location.assign(formatURL())
  }

  return (
    <Modal
      open={escalate.escalationOpen}
      onClose={() => {
        escalate.setEscalationOpen(false)
      }}
    >
      <Modal.Header>Escalation Request</Modal.Header>
      <Modal.Content>{error}</Modal.Content>
      <Modal.Content>
        <span>
          {`Clicking 'Yes' will take you to Concierge group that owns ${env.namespace} namespace where you can escalate yourself.`}
        </span>
        <Button
          className="confirm-modal no"
          onClick={() => {
            escalate.setEscalationOpen(false)
          }}
        >
          No, thanks!
        </Button>
        <Button className="confirm-modal" onClick={handleClick}>
          Yes, to Concierge!
        </Button>
      </Modal.Content>
    </Modal>
  )
}

EscalationModal.propTypes = {
  conciergeLink: PropTypes.string.isRequired,
}

export default EscalationModal
