import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import '../app-detail-view/app-detail-view.css'
import Loader from 'react-loader-spinner'
import { useQuery } from '@apollo/client'
import { Grid } from '@wework/dieter-ui'
import TopBar from '../../components/TopBar'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import AppHistoricalStatus from '../app-detail-view/app-historical-status.component'
import DeploysOverTime from '../app-detail-view/deploys-over-time.component'
import InstanceStatuses from '../app-detail-view/instances-statuses.component'
import EnvVariables from '../../components/EnvVariables/EnvVariables'
import PrometheusAlertsView from '../../components/Alerts/PrometheusAlertsView'
import {
  AuthContext,
  escalationHelper,
} from '../../components/Contexts/AuthContext'
import { EnvironmentContext } from '../../components/Contexts/EnvironmentContext'
import {
  EscalateContext,
  Escalation,
} from '../../components/Contexts/EscalateContext'
import {
  GET_APP_DETAILS as query,
  GetAppDetailsVariables,
  GetAppDetailsQueryResult,
  AppDeploymentInfo,
  UserPermissions,
  Dashboards,
} from '../../graphql/appDetails'
import EscalationModal from '../../components/Escalation/EscalationModal'
import CronJobStatuses from './cron-job-statuses.component'
import SidePanel from './side-panel.component'
import { sidebarLinksIds, getNavObj } from './nav-props'

export const AppDetailView: React.FunctionComponent = () => {
  const { cluster, namespace, appName } = useParams<GetAppDetailsVariables>()
  const [escalationOpen, setEscalationOpen] = useState<boolean>(false)

  const { loading, data, refetch } = useQuery<
    GetAppDetailsQueryResult,
    GetAppDetailsVariables
  >(query, {
    variables: {
      appName,
      namespace,
      cluster,
    },
    pollInterval: 60000,
  })

  const deploymentInfo: AppDeploymentInfo =
    data?.getAppDetails.deploymentInfo || ({} as AppDeploymentInfo)

  const {
    deploymentList,
    activeDeployment,
    conciergeTeamId,
    conciergeTeamLink,
  } = deploymentInfo

  const services = data?.getAppDetails.services

  const envVars: GetAppDetailsVariables = {
    appName,
    namespace,
    cluster,
  }

  const userPermissions =
    data?.getAppDetails.userPermissions || ({} as UserPermissions)
  const dashboards = data?.getAppDetails.dashboards || ({} as Dashboards)

  function requireEscalationForCallback<E extends any[]>(
    cb: (...args: E) => void,
  ): (...args: E) => void {
    return (...args: E) => {
      if (!userPermissions.isEscalated) {
        setEscalationOpen(true)
        return
      }
      cb(...args)
    }
  }

  const escalationVars: Escalation = {
    escalationOpen,
    setEscalationOpen,
    requireEscalationForCallback,
  }

  return (
    <header className="app">
      <EnvironmentContext.Provider value={envVars}>
        <EscalateContext.Provider value={escalationVars}>
          <AuthContext.Provider
            value={escalationHelper(userPermissions, envVars)}
          >
            <TopBar />
            <EscalationModal conciergeLink={conciergeTeamLink} />
            <Grid className="app-detail-grid">
              <Grid.Column width={3}>
                <SidePanel
                  conciergeTeamId={conciergeTeamId}
                  conciergeTeamLink={conciergeTeamLink}
                />
              </Grid.Column>
              <Grid.Column width={11}>
                {loading ? (
                  <div className="app-body">
                    <div style={{ textAlign: 'center', marginTop: '5vh' }}>
                      <Loader
                        type="RevolvingDot"
                        color="#FFFFFF"
                        height={100}
                        width={100}
                        timeout={3000}
                      />
                      <div style={{ marginTop: '20px', color: 'white' }}>
                        Loading...
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="app-body">
                    <AppHistoricalStatus
                      nav={getNavObj(sidebarLinksIds.HISTORICAL_STATUS)}
                      dashboards={dashboards}
                    />
                    <InstanceStatuses
                      nav={getNavObj(sidebarLinksIds.INSTANCES_AND_STATUSES)}
                      services={services}
                      refetch={refetch}
                    />
                    <DeploysOverTime
                      nav={getNavObj(sidebarLinksIds.DEPLOYS_OVER_TIME)}
                      deploymentList={deploymentList}
                      activeDeployment={activeDeployment}
                    ></DeploysOverTime>
                    <EnvVariables
                      nav={getNavObj(sidebarLinksIds.ENVIRONMENT_VARIABLES)}
                    />
                    <CronJobStatuses
                      nav={getNavObj(sidebarLinksIds.CRON_JOBS)}
                    />
                    <PrometheusAlertsView
                      services={services}
                      nav={getNavObj(sidebarLinksIds.ALERTS_LIST)}
                    />
                  </div>
                )}
              </Grid.Column>
            </Grid>
          </AuthContext.Provider>
        </EscalateContext.Provider>
      </EnvironmentContext.Provider>
    </header>
  )
}
