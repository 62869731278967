import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Checkbox, Form, Button, CheckboxProps } from '@wework/dieter-ui'
import '../app-detail-view/checkbox-modal.css'

export interface CheckboxModalProps {
  fieldNames: string[]
  defaultCheckedValue: boolean | undefined
  disableForm: boolean | undefined
  submitCallback: (checkedFieldNames: string[]) => void
  error: string | undefined
  open: boolean
  setOpen: (isOpen: boolean) => void
  trigger: JSX.Element
  title: string
  subtitle: string
  radio: boolean | undefined
  warning: string | undefined
}

interface CheckboxMap {
  [key: string]: boolean
}

const getInitialState = (
  fieldNames: string[],
  defaultValue: boolean,
): CheckboxMap => {
  return fieldNames.reduce((acc, val) => {
    acc[val] = defaultValue
    return acc
  }, {} as CheckboxMap)
}

const CheckboxModal: React.FunctionComponent<React.PropsWithChildren<
  CheckboxModalProps
>> = props => {
  const {
    open,
    setOpen,
    error,
    disableForm,
    title,
    subtitle,
    radio,
    warning,
  } = props
  const defaultCheckedValue = props.defaultCheckedValue || false

  const [checkedMap, setCheckedMap] = useState<CheckboxMap>(
    getInitialState(props.fieldNames, defaultCheckedValue),
  )

  const handleCheckboxClick = (
    e: React.FormEvent<HTMLInputElement>,
    checkedData: CheckboxProps,
  ) => {
    const value = checkedData.value || ''
    e.preventDefault()
    if (radio) {
      const defaultMap = getInitialState(props.fieldNames, false)
      setCheckedMap({
        ...defaultMap,
        ...({ [value]: checkedData.checked } as CheckboxMap),
      })
      return
    }
    setCheckedMap({
      ...checkedMap,
      ...({ [value]: checkedData.checked } as CheckboxMap),
    })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const values = Object.keys(checkedMap).filter(
      key => checkedMap[key] === true,
    )
    props.submitCallback(values)
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      trigger={props.trigger}
      className="modal-box"
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          <p>{subtitle}</p>
          {Object.keys(checkedMap).map(name => {
            return (
              <Checkbox
                key={name}
                name={radio ? 'radioGroup' : name}
                label={name}
                value={name}
                onChange={handleCheckboxClick}
                checked={checkedMap[name]}
                disabled={disableForm}
                className="modal-checkbox"
                radio={radio}
              />
            )
          })}
          <Button type="submit" disabled={disableForm} className="modal-button">
            Submit
          </Button>
        </Form>
        {warning === '' ? '' : <span className="warning">{warning}</span>}
        {error === '' ? '' : <span className="error">{error}</span>}
      </Modal.Content>
    </Modal>
  )
}

CheckboxModal.propTypes = {
  fieldNames: PropTypes.array.isRequired,
  defaultCheckedValue: PropTypes.bool,
  disableForm: PropTypes.bool,
  error: PropTypes.string,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.any.isRequired,
  submitCallback: PropTypes.any.isRequired,
  trigger: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  radio: PropTypes.bool,
  warning: PropTypes.string,
}

export default CheckboxModal
