import { SupportedClusters } from '../../graphql/statuses'

/**
 * Observer represents a unit of observer function and their associated uuid.
 */
class Observer {
  constructor(readonly uuid: string, readonly func: ObserverFunction) {}
}

/**
 * ObserverFunction is an interface type that requires *FilterEvent* as the argument.
 */
type ObserverFunction = (changedFilters: FilterEvent) => void

/**
 * FilterEvent stores the state during the time of the event.
 */
export class FilterEvent {
  constructor(
    readonly cluster: string,
    readonly namespaces: string[],
    readonly searchQuery: string,
  ) {}

  hasNamespaces(): boolean {
    return this.namespaces.length > 0
  }

  hasSearchQuery(): boolean {
    return !!this.searchQuery
  }
}

/**
 * AppliedFilters keeps the state for all applied filters. It allows external observer to
 * observe the state transition.
 */
export class AppliedFilters {
  constructor(
    private namespaces = new Array<string>(),
    private searchQuery = '',
    private cluster = SupportedClusters.ProdUsEast as string,
    private observers = new Map<string, Observer>(),
  ) {}

  /**
   * Subscribe to the events while applying the filters.
   *
   * @param uuid {string} The unique reference for the observer to ensure regardless of component's reload
   *             the current subscriber is from the active component.
   * @param func {ObserverFunction} The callback function
   */
  public subscribe(uuid: string, func: ObserverFunction): void {
    const observer = new Observer(uuid, func)

    this.observers.set(uuid, observer)
  }

  setNamespaces(namespaces: string[]) {
    if (this.namespaces.join() !== namespaces.join()) {
      this.namespaces = namespaces

      this.triggerChangedEvent()
    }
  }

  setSearchQuery(searchQuery: string) {
    if (this.searchQuery !== searchQuery) {
      this.searchQuery = searchQuery

      this.triggerChangedEvent()
    }
  }

  setCluster(cluster: string) {
    if (cluster !== this.cluster) {
      this.cluster = cluster

      this.triggerChangedEvent()
    }
  }

  private triggerChangedEvent() {
    this.observers.forEach((observer, _) => {
      observer.func(
        new FilterEvent(this.cluster, this.namespaces, this.searchQuery),
      )
    })
  }
}
