import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Form, Grid } from '@wework/dieter-ui'
import {
  AppService,
  SupportedScopeTypesForElkQueries,
} from '../../graphql/appDetails'
import { ElkBuildQueryLinkViewProps } from './ElkBuildQueryLink'
import { renderButtonSection } from './SharedHelpers'
import './ElkBuildQueryLink.css'

export interface ElkBuildServicesLinkViewProps
  extends ElkBuildQueryLinkViewProps {
  services: AppService[]
}

interface CheckboxMap {
  [key: string]: boolean
}

function initFormState(
  scope: SupportedScopeTypesForElkQueries,
  services: AppService[],
): { scope: SupportedScopeTypesForElkQueries; scopeValuesMap: CheckboxMap } {
  let scopeValuesMap: CheckboxMap

  switch (scope) {
    case SupportedScopeTypesForElkQueries.Pod:
      scopeValuesMap = services.reduce((acc, val) => {
        val.podList.forEach(x => {
          acc[x.name] = false // pods should be unselected by default
        })
        return acc
      }, {} as CheckboxMap)
      break
    case SupportedScopeTypesForElkQueries.Service:
    default:
      scopeValuesMap = services.reduce((acc, val) => {
        acc[val.name] = true // services should be selected by default
        return acc
      }, {} as CheckboxMap)
      break
  }
  return {
    scope,
    scopeValuesMap,
  }
}

const ElkBuildServicesLinkView: React.FunctionComponent<ElkBuildServicesLinkViewProps> = props => {
  const { services, error, elkLink, updateElkLink, loading } = props
  const [formState, setFormState] = useState<{
    scope: SupportedScopeTypesForElkQueries
    scopeValuesMap: CheckboxMap
  }>(initFormState(SupportedScopeTypesForElkQueries.Service, services))
  const { scope, scopeValuesMap } = formState

  const changeFormStateCallback = () => {
    const startTime = '3h' // use 3h offset by default
    const scopeValues = Object.keys(formState.scopeValuesMap).filter(
      x => formState.scopeValuesMap[x],
    )
    void updateElkLink(formState.scope, scopeValues, startTime, undefined)
  }

  useEffect(changeFormStateCallback, [formState])

  return (
    <React.Fragment>
      <Form>
        <div className="scope-type">
          <p>Query by:</p>
          <Grid columns={2}>
            <Grid.Column>
              <Checkbox
                key={SupportedScopeTypesForElkQueries.Service}
                name="scope"
                label={SupportedScopeTypesForElkQueries.Service}
                onChange={() =>
                  setFormState(
                    initFormState(
                      SupportedScopeTypesForElkQueries.Service,
                      services,
                    ),
                  )
                }
                className={`modal-checkbox modal-checkbox-${SupportedScopeTypesForElkQueries.Service}`}
                checked={scope === SupportedScopeTypesForElkQueries.Service}
                type="radio"
                radio={true}
              />
            </Grid.Column>
            <Grid.Column>
              <Checkbox
                key={SupportedScopeTypesForElkQueries.Pod}
                name="scope"
                label={SupportedScopeTypesForElkQueries.Pod}
                onChange={() =>
                  setFormState(
                    initFormState(
                      SupportedScopeTypesForElkQueries.Pod,
                      services,
                    ),
                  )
                }
                className={`modal-checkbox modal-checkbox-${SupportedScopeTypesForElkQueries.Pod}`}
                checked={scope === SupportedScopeTypesForElkQueries.Pod}
                type="radio"
                radio={true}
              />
            </Grid.Column>
          </Grid>
        </div>
        <div>
          <p>Select values to include:</p>
          {Object.keys(scopeValuesMap).map(name => {
            return (
              <Checkbox
                key={name}
                name={name}
                label={name}
                value={name}
                onChange={(_e, value) => {
                  const newValue = {
                    ...scopeValuesMap,
                    [name]: !!value.checked,
                  }
                  setFormState({
                    scope,
                    scopeValuesMap: newValue,
                  })
                }}
                checked={scopeValuesMap[name]}
                className="modal-checkbox"
              />
            )
          })}
        </div>

        {renderButtonSection(loading, elkLink)}
      </Form>
      {error && <span className="error">{error}</span>}
    </React.Fragment>
  )
}

ElkBuildServicesLinkView.propTypes = {
  services: PropTypes.array.isRequired,
  error: PropTypes.string.isRequired,
  elkLink: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  updateElkLink: PropTypes.any.isRequired,
}

export default ElkBuildServicesLinkView
