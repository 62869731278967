import React from 'react'
import { Button } from '@wework/dieter-ui'

export function renderButtonSection(
  loading: boolean,
  elkLink: string,
): JSX.Element {
  return (
    <div className="button-section">
      {!loading && elkLink !== '' ? (
        <a
          href={elkLink}
          target="_blank"
          className="ui button modal-button"
          rel="noreferrer noopener"
        >
          Go to Kibana
        </a>
      ) : (
        <Button disabled={loading} className="modal-button">
          Loading...
        </Button>
      )}
    </div>
  )
}
