export function getGraphQLErrorMessage(err: any): string {
  try {
    return err.graphQLErrors
      .map((x: any) => {
        return x.extensions.validationErrors.map((y: any) =>
          Object.values(y.constraints).join('\n'),
        )
      })
      .toString() as string
  } catch {
    return err.toString()
  }
}

export async function delay(
  callback: (...args: any) => Promise<any>,
  ms: number,
): Promise<void> {
  await new Promise(resolve => setTimeout(resolve, ms))
  void callback()
}
