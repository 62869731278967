import { useEffect } from 'react'
import { initialize as init, pageview } from 'react-ga'
import { useHistory } from 'react-router-dom'
import { getGaParams } from './config'

export const initialize = (hostname: string): void => {
  const [trackingId, options] = getGaParams(hostname)
  init(trackingId, options)
}

export const useTracking = (): void => {
  const { listen } = useHistory()

  useEffect(() => {
    return listen(location => {
      pageview(location.pathname + location.search)
    })
  }, [listen])
}
