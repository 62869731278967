import { URLSearchParams } from 'url'
import { FilterEvent } from './applied-filters'

/**
 * FilterLocationState is an interface for requiring *changedEvent* property.
 * It is used for storing a transient state while pushing new state to the browser history.
 */
export interface FilterLocationState {
  changedEvent: FilterEvent
}

/**
 * FilterParamNames an enum for centralizing all parameter names.
 */
export enum FilterParamNames {
  Cluster = 'cluster',
  QueryNamespaces = 'namespaces',
  QuerySearchQuery = 'search',
}

/**
 * FilterParams extracts the parameters from the respective url sources.
 */
export class FilterParams {
  public namespaces: string[]
  public searchQuery: string

  constructor(readonly cluster: string, params: URLSearchParams) {
    this.searchQuery = params.get(FilterParamNames.QuerySearchQuery) || ''
    this.namespaces = this.parseMultiValues(
      params.get(FilterParamNames.QueryNamespaces),
      [],
    )
  }

  private parseMultiValues(
    value: string | null,
    defaultValues: string[],
  ): string[] {
    if (value == null) return defaultValues

    const multiValues = decodeURIComponent(value).split(/\s*,\s*/)

    return multiValues.length > 0 ? multiValues : defaultValues
  }
}
