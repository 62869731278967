import React from 'react'
import { useLocation } from 'react-router-dom'
import { Login } from './pages/login/login.component'
import { AppStatusList } from './pages/app-status-list/app-status-list.component'
import { AppDetailView } from './pages/app-detail-view/app-detail-view.component'
import { FilterEvent } from './pages/app-status-list/applied-filters'

export interface WectlUiRoute {
  path: string
  canBeViewedAsUnauthenticated?: boolean
  page: React.Component<any> | React.SFC<any>
}

export const appStatusListRoute = '/app-status-list/:cluster?'

export const routes: WectlUiRoute[] = [
  { path: '/login', canBeViewedAsUnauthenticated: true, page: Login },
  { path: appStatusListRoute, page: AppStatusList },
  {
    path: '/app-detail-view/:cluster/:namespace/:appName',
    page: AppDetailView,
  },
  { path: '/', page: AppStatusList },
]

/**
 * Builds an url to the cluster specific status list page.
 *
 * @param {FilterEvent} event
 * @return {string}
 */
export function urlToAppStatusList(event: FilterEvent): string {
  const urlParts = [appStatusListRoute.replace(':cluster?', event.cluster)]

  if (event.hasNamespaces()) {
    urlParts.push('?')
    urlParts.push(
      `namespaces=${encodeURIComponent(event.namespaces.join(','))}`,
    )
  }

  if (event.hasSearchQuery()) {
    if (urlParts.includes('?')) {
      urlParts.push('&')
    } else {
      urlParts.push('?')
    }

    urlParts.push(`search=${encodeURIComponent(event.searchQuery)}`)
  }

  return urlParts.join('')
}

/**
 * @returns {URLSearchParams}
 */
export function useLocationQuery() {
  return new URLSearchParams(useLocation().search)
}
