import React, { FunctionComponent, useState } from 'react'
import { Modal, Image } from '@wework/dieter-ui'
import PropTypes from 'prop-types'
import './Error.css'
import Exclamation from './exclamation.svg'
import X from './X.svg'

interface ErrorProps {
  children: string | null
}

const ErrorPopup: FunctionComponent<ErrorProps> = props => {
  const { children } = props
  const [open, setOpen] = useState<boolean>(true)

  return (
    <Modal className="modal-mod" open={open}>
      <Modal.Content>
        <div className="ui container error">
          <div className="error-string-box">
            <span className="error-string">{children}</span>
          </div>
          <div className="error-oval">
            <span className="errortext">Error</span>
            <Image src={Exclamation} className="exclamation" />
          </div>
          <Image
            src={X}
            className="times-svg"
            onClick={() => {
              setOpen(false)
            }}
          />
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default ErrorPopup

ErrorPopup.propTypes = {
  children: PropTypes.string,
}
