export enum sidebarLinksIds {
  HISTORICAL_STATUS = 'historical-status',
  INSTANCES_AND_STATUSES = 'instances-and-statuses',
  DEPLOYS_OVER_TIME = 'deploys-over-time',
  ENVIRONMENT_VARIABLES = 'environment-variables',
  CRON_JOBS = 'cron-jobs',
  ALERTS_LIST = 'alerts-list',
}

export function getNavObj(id: sidebarLinksIds): NavProps {
  const sidebarLinksMap: { [id: string]: string } = {
    [sidebarLinksIds.HISTORICAL_STATUS]: 'App Status',
    [sidebarLinksIds.INSTANCES_AND_STATUSES]: 'Instances and Statuses',
    [sidebarLinksIds.DEPLOYS_OVER_TIME]: 'Deploys over Time',
    [sidebarLinksIds.ENVIRONMENT_VARIABLES]: 'Environment Variables',
    [sidebarLinksIds.CRON_JOBS]: 'Cron Jobs',
    [sidebarLinksIds.ALERTS_LIST]: 'Alerts List',
  }

  return { id, title: sidebarLinksMap[id] }
}
export default interface NavProps {
  id: string
  title: string
}
