import { createHttpLink, InMemoryCache, ApolloClient } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getAuthToken } from '../auth'
import { getGraphQLEndpoint } from '../config'

const authLink = setContext((_, { headers }) => {
  const authToken = getAuthToken()
  return {
    headers: {
      ...headers,
      Authorization: authToken?.value ? `Bearer ${authToken.value}` : '',
    },
  }
})

const link = authLink.concat(
  createHttpLink({
    uri: getGraphQLEndpoint(window.location.hostname),
  }),
)

const cache = new InMemoryCache()

export const client = new ApolloClient({ link, cache })
