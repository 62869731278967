import React, { useState } from 'react'
import Loader from 'react-loader-spinner'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import PropTypes from 'prop-types'
import './app-historical-status.css'
import { useQuery } from '@apollo/client'
import moment from 'moment'
import NoData from '../../components/NoData/NoData'
import DashboardsPopup from '../../components/Dashboards/DashboardsPopup'
import { Dashboards } from '../../graphql/appDetails'
import { CalendarWrapper } from '../../components/Calendar/CalendarWrapper'

import {
  GET_APP_HISTORICAL_STATUSES as query,
  GetAppHistoricalStatusesQueryResult as QueryResult,
  GetAppHistoricalStatusesVariables as GqlVars,
} from '../../graphql/statuses'

import { useEnv } from '../../components/Contexts/EnvironmentContext'

import { MouseOverEventInfo, buildMouseOverHandler } from '../../mouseover'
import NavProps from './nav-props'

interface AppHistoricalStatusProps {
  nav: NavProps
  dashboards: Dashboards
}

export const AppHistoricalStatus: React.FunctionComponent<AppHistoricalStatusProps> = props => {
  const [range] = useState<string>('4h')
  const [resolution] = useState<string>('5m')
  const [offset, setOffset] = useState<string>()
  const [mouseOverInfo, setMouseOverInfo] = useState<MouseOverEventInfo>()
  const envContext = useEnv()
  const { loading, data } = useQuery<QueryResult, GqlVars>(query, {
    variables: {
      ...envContext,
      range,
      resolution,
      offset,
    },
    pollInterval: 60000,
  })

  const handleMouseOver = buildMouseOverHandler(setMouseOverInfo)

  function buildNoDataMsg(Data: any) {
    return Data
      ? 'No historical status found for this time and date'
      : 'No historical status found for this app'
  }

  if (loading || !data || !data.getAppHistoricalStatuses)
    return (
      <div style={{ textAlign: 'center', marginTop: '5vh' }}>
        <Loader
          type="RevolvingDot"
          color="#FFFFFF"
          height={100}
          width={100}
          timeout={3000}
        />
        <div style={{ marginTop: '20px', color: 'white' }}>Loading...</div>
      </div>
    )

  const {
    statuses: appStatuses,
    startTime,
    endTime,
    elkLink,
  } = data.getAppHistoricalStatuses

  return (
    <div className="a-d-v-box" id={props.nav.id}>
      <p className="p box-header">
        <span className="details-section-title">{props.nav.title}</span>
        <DashboardsPopup dashboards={props.dashboards} />
      </p>
      {!appStatuses || appStatuses.length === 0 ? (
        <NoData>{buildNoDataMsg(data)}</NoData>
      ) : (
        <React.Fragment>
          <div className="historical-status-container">
            {appStatuses.map((app, idx) => (
              <div
                key={`{${envContext.appName}-${app.timestamp}`}
                data-index={idx}
                className={`app-status ${app.aggregateStatus}`}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOver}
              />
            ))}
            {!mouseOverInfo ? (
              ''
            ) : (
              <div
                className={`mouseover-container ${
                  appStatuses[mouseOverInfo.elemIdx].aggregateStatus
                }`}
                style={{
                  left: `${mouseOverInfo.positionX}px`,
                }}
              >
                <div className="timestamp">
                  {moment(
                    appStatuses[mouseOverInfo.elemIdx].timestamp,
                  ).toLocaleString()}
                </div>
                <ul>
                  {appStatuses[mouseOverInfo.elemIdx].serviceStatuses.map(
                    service => (
                      <li
                        key={`${envContext.appName}-${service.name}`}
                        className="app-service"
                      >
                        <span className="name">{service.name}</span>
                        <span className={`status ${service.status}`}>
                          {service.status}
                        </span>
                        <span className="instances">{service.instances}</span>
                      </li>
                    ),
                  )}
                </ul>
              </div>
            )}
          </div>
        </React.Fragment>
      )}
      {data && (
        <CalendarWrapper
          setOffset={setOffset}
          offset={offset}
          rangeInHours={range}
          startTime={startTime}
          endTime={endTime}
          elkLink={elkLink}
        />
      )}
    </div>
  )
}

AppHistoricalStatus.propTypes = {
  nav: PropTypes.any.isRequired,
  dashboards: PropTypes.any.isRequired,
}

export default AppHistoricalStatus
