import React, { FunctionComponent, ReactNode } from 'react'
import PropTypes from 'prop-types'
import './NoData.css'
import { Container } from '@wework/dieter-ui'

interface NoDataProps {
  children: ReactNode
}

const NoData: FunctionComponent<NoDataProps> = props => (
  <Container className="no-data-box">{props.children}</Container>
)

export default NoData

NoData.propTypes = {
  children: PropTypes.node,
}
