import React from 'react'
import { useAuth } from '../Contexts/AuthContext'
import { useEnv } from '../Contexts/EnvironmentContext'
import './Escalation.css'

const EscalatedStatusBar = () => {
  const auth = useAuth()
  const env = useEnv()

  const infoBarOptions = {
    escalated: `You currently have Escalated admin privileges in namespace '${env.namespace}', in cluster '${env.cluster}'`,
  }

  function pickOption(): string | null {
    if (auth.isEscalated === true) {
      return infoBarOptions.escalated
    }
    return null
  }

  if (pickOption() === null) {
    return null
  }
  return <span className="escalation topbar-status">{pickOption()}</span>
}

export default EscalatedStatusBar
