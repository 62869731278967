import React, { FunctionComponent, useState } from 'react'
import { Modal, Button } from '@wework/dieter-ui'
import PropTypes from 'prop-types'
import { getGraphQLErrorMessage } from '../../graphql/helpers'

export interface DeleteModalProps {
  variableName: string
  onSubmit: () => Promise<void>
  trigger: React.ReactNode
}

const DeleteModal: FunctionComponent<DeleteModalProps> = props => {
  const { onSubmit, variableName, trigger } = props
  const [open, setOpen] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [disabled, setDisabled] = useState<boolean>(false)

  const onClickHandler = async () => {
    try {
      setDisabled(true)
      await onSubmit()
      setOpen(false)
    } catch (e) {
      setError(getGraphQLErrorMessage(e))
    }
    setDisabled(false)
  }

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => {
        setOpen(false)
      }}
      trigger={trigger}
    >
      <Modal.Header>Delete Environment Variable</Modal.Header>
      <Modal.Content>
        <p>
          Are you sure you want to delete variable <i>{variableName}</i>?
        </p>
        <p>
          <b>This cannot be undone!</b>
        </p>
        <p className="error">{error}</p>
        <Button
          className="confirm-modal no"
          disabled={disabled}
          onClick={() => {
            setOpen(false)
          }}
        >
          No, thanks!
        </Button>
        <Button
          className="confirm-modal yes"
          disabled={disabled}
          onClick={onClickHandler}
        >
          Confirm
        </Button>
      </Modal.Content>
    </Modal>
  )
}

DeleteModal.propTypes = {
  variableName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  trigger: PropTypes.node.isRequired,
}

export default DeleteModal
