import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@wework/dieter-ui'
import './instances-statuses.css'
import { Table } from '@wework/dieter-ui'
import { useMutation, ApolloQueryResult } from '@apollo/client'
import NoData from '../../components/NoData/NoData'
import {
  AppService,
  GetAppDetailsVariables,
  GetAppDetailsQueryResult,
  INSTANCES_RESTART as mutation,
  InstancesRestartArgs,
  InstancesRestartResult,
} from '../../graphql/appDetails'
import { getGraphQLErrorMessage, delay } from '../../graphql/helpers'
import { useAuth } from '../../components/Contexts/AuthContext'
import { useEnv } from '../../components/Contexts/EnvironmentContext'
import { useEscalation } from '../../components/Contexts/EscalateContext'
import ElkBuildQueryLink from '../../components/ElkBuildQueryLink/ElkBuildQueryLink'
import ElkBuildServicesLinkView from '../../components/ElkBuildQueryLink/ElkBuildServicesLinkView'
import NavProps from './nav-props'
import CheckboxModal from './checkbox-modal-component'
import './app-detail-view.css'

interface InstancesProps {
  nav: NavProps
  services: AppService[] | undefined
  refetch: (
    variables?: Partial<GetAppDetailsVariables> | undefined,
  ) => Promise<ApolloQueryResult<GetAppDetailsQueryResult>>
}

export const InstanceStatuses: React.FunctionComponent<React.PropsWithChildren<
  InstancesProps
>> = props => {
  const { services, refetch } = props
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [restartError, setRestartError] = useState<string>('')
  const [instancesRestart, { loading }] = useMutation<
    InstancesRestartResult,
    InstancesRestartArgs
  >(mutation)

  const userPermissions = useAuth()
  const { cluster, namespace, appName } = useEnv()
  const { requireEscalationForCallback } = useEscalation()

  const restartInstancesCallback = async (serviceNames: string[]) => {
    try {
      const res = await instancesRestart({
        variables: { cluster, namespace, appName, serviceNames },
      })
      if (res.data && res.data.instancesRestart.status !== 'success') {
        throw new Error(
          `instancesRestart responded with status: ${res.data.instancesRestart.status}`,
        )
      }
      setModalOpen(false)
      setRestartError('')
      void delay(refetch, 5000)
    } catch (err) {
      setRestartError(getGraphQLErrorMessage(err))
    }
  }

  return (
    <div className="a-d-v-box" id={props.nav.id}>
      <p className="p box-header">
        <span className="details-section-title">{props.nav.title}</span>
        <span className="details-section-actions">
          {userPermissions.hasWriteAccess && (
            <React.Fragment>
              <CheckboxModal
                defaultCheckedValue={true}
                fieldNames={services?.map(x => x.name) || []}
                error={restartError}
                open={modalOpen}
                setOpen={requireEscalationForCallback(setModalOpen)}
                trigger={<span className="detail-action-button">Restart</span>}
                submitCallback={restartInstancesCallback}
                disableForm={loading}
                title="Service Restart"
                subtitle="Select the services to be restarted"
                radio={false}
                warning=""
              />
              <span className="detail-action-button divider" />
            </React.Fragment>
          )}
          <Modal
            trigger={<span className="detail-action-button">Get Logs</span>}
            content={
              <ElkBuildQueryLink>
                {queryProps => (
                  <ElkBuildServicesLinkView
                    services={services || []}
                    {...queryProps}
                  />
                )}
              </ElkBuildQueryLink>
            }
            header={'Build Elk Link from Services'}
          />
        </span>
      </p>
      {!services || services.length === 0 ? (
        <NoData>No instances found for this App</NoData>
      ) : (
        <div>
          {services.map((service, idx) => {
            return (
              <Table key={`${service.name}-${idx}`} singleLine sortable striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={5}>
                      Name ({service.name})
                    </Table.HeaderCell>
                    <Table.HeaderCell>Ready</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Restarts</Table.HeaderCell>
                    <Table.HeaderCell>Age</Table.HeaderCell>
                    <Table.HeaderCell>Version</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body className="instances">
                  {service.podList.map((s, i) => (
                    <Table.Row className="instances" key={i}>
                      <Table.Cell width={5}>{s.name}</Table.Cell>
                      <Table.Cell>{s.instances}</Table.Cell>
                      <Table.Cell>{s.status}</Table.Cell>
                      <Table.Cell>{s.restarts}</Table.Cell>
                      <Table.Cell>{s.age}</Table.Cell>
                      <Table.Cell>{s.version}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            )
          })}
        </div>
      )}
    </div>
  )
}
InstanceStatuses.propTypes = {
  services: PropTypes.any,
  nav: PropTypes.any,
  refetch: PropTypes.any,
}

export default InstanceStatuses
