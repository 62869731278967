import React from 'react'
import { Grid, Popup } from '@wework/dieter-ui'
import './app-detail-view.css'
import { FaRegQuestionCircle } from 'react-icons/fa'

const DeployOverTimeHeader = () => {
  return (
    <Grid columns={5} className="deploys-text-header">
      <Grid.Column width={1} style={{ right: 15 }}>
        Active
      </Grid.Column>
      <Grid.Column width={2}>Order</Grid.Column>
      <Grid.Column width={5}>Deploy Time</Grid.Column>
      <Grid.Column width={4}>
        <div style={{ margin: '0 0 0 30px' }}>Version</div>
      </Grid.Column>
      <Grid.Column width={4}>
        <Popup
          trigger={<span>Changes {<FaRegQuestionCircle color="grey" />}</span>}
          on="hover"
          content={
            <span>
              <p>
                Github links not working properly? This can occur if your Quay
                images/Wectl versions dont match your Github tags exactly.
              </p>
              <p>
                Some apps have a CI process that tags their quay images
                differently from their github SHAs/tags, which tends to break
                this functionality.
              </p>
              <p>
                For example, if your team tags images with the environment or
                branch name and the SHA, so instead of just having v1.0.0 they
                might have qa-v1.0.0 where v1.0.0 is the git revision and qa is
                the environment. In this case, you can edit the URL, removing
                the the environment name, and the diff will work correctly.
              </p>
            </span>
          }
          basic
        />
      </Grid.Column>
    </Grid>
  )
}

export default DeployOverTimeHeader
