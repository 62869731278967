import { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import {
  AppService,
  BuildElkQueryLinkArgs,
  BuildElkQueryLinkResult,
  BUILD_ELK_QUERY_LINK as query,
  SupportedScopeTypesForElkQueries,
} from '../../graphql/appDetails'
import { getGraphQLErrorMessage } from '../../graphql/helpers'
import { useEnv } from '../Contexts/EnvironmentContext'
export interface ElkBuildQueryLinkProps {
  services: AppService[]
}

interface ElkBuildQueryLinkStateProps {
  loading: boolean
  elkLink: string
  error: string
}

export interface ElkBuildQueryLinkViewProps
  extends ElkBuildQueryLinkStateProps {
  updateElkLink: (
    scope: SupportedScopeTypesForElkQueries,
    scopeValues: string[],
    startTime: string,
    endTime: string | undefined,
  ) => Promise<void>
}
export function ElkBuildQueryLink(props: {
  children: (result: ElkBuildQueryLinkViewProps) => JSX.Element | null
}): JSX.Element | null {
  const { children } = props
  const { cluster, namespace } = useEnv()
  const client = useApolloClient()

  const [state, setState] = useState<ElkBuildQueryLinkStateProps>({
    loading: false,
    elkLink: '',
    error: '',
  })

  const updateElkLink = _.debounce(
    (
      scope: SupportedScopeTypesForElkQueries,
      scopeValues: string[],
      startTime: string,
      endTime: string | undefined,
    ): Promise<void> => {
      setState({
        ...state,
        loading: true,
      })
      return client
        .query<BuildElkQueryLinkResult, BuildElkQueryLinkArgs>({
          query,
          variables: {
            cluster,
            namespace,
            scope,
            scopeValues,
            startTime,
            endTime,
          },
        })
        .then(result => {
          setState({
            ...state,
            elkLink: result.data.buildElkQueryLink.link,
            loading: false,
          })
        })
        .catch(e =>
          setState({
            ...state,
            error: getGraphQLErrorMessage(e),
            loading: false,
          }),
        )
    },
    500,
  )

  return children && children({ ...state, updateElkLink })
}

ElkBuildQueryLink.propTypes = {
  children: PropTypes.func.isRequired,
}

export default ElkBuildQueryLink
