const localStorageAuthKey = 'okta-token-storage'

interface AuthObject {
  value: string
  expiresAt: number
}

const expirationListenerCallbacks: Function[] = []

export function isAuthenticated(): boolean {
  const auth = getAuthToken()
  return !!auth?.value && auth?.expiresAt > Date.now() / 1000
}

export function getAuthToken(): AuthObject | null {
  const token = localStorage.getItem(localStorageAuthKey)
  return (token && JSON.parse(token).idToken) || null
}

export function addCallbackToExpiration(cb: Function): void {
  expirationListenerCallbacks.push(cb)
}

;(() => {
  /*
   * Initialize this to isAuthenticated(), because users can be authenticated
   * at when the page loads if they previously logged in. If they are,
   * we will want to trigger the callbacks when their token expires.
   *
   * If isAuthenticated is false, we can rely on the routes that load at startup
   * to redirect users to the login page. No need to trigger them.
   */
  let triggerCallbacksIfUnauthenticated = isAuthenticated()
  const cadence = 10000

  const intervalCb = () => {
    if (isAuthenticated()) {
      triggerCallbacksIfUnauthenticated = true
    } else if (triggerCallbacksIfUnauthenticated) {
      triggerCallbacksIfUnauthenticated = false
      expirationListenerCallbacks.forEach(cb => cb())
    }

    // reschedule intervalCb
    setTimeout(intervalCb, cadence)
  }

  // kickoff loop of intervalCb
  setTimeout(intervalCb, cadence)
})()
