import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from 'semantic-ui-react'
import { useEnv } from '../../components/Contexts/EnvironmentContext'
import { sidebarLinksIds, getNavObj } from './nav-props'
import './LeftSideBar.css'

interface TemporaryPropsToBeRemoved {
  conciergeTeamLink: string
  conciergeTeamId: string
}

const SidePanel: React.FunctionComponent<TemporaryPropsToBeRemoved> = props => {
  const { cluster, appName, namespace } = useEnv()
  return (
    <div className="side-panel">
      <Grid>
        <Grid.Column width={3}>
          <div className="side-panel-title">
            {appName}
            {props.conciergeTeamLink ? (
              <React.Fragment>
                {' '}
                (
                <a
                  href={props.conciergeTeamLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {props.conciergeTeamId}
                </a>
                )
              </React.Fragment>
            ) : (
              ''
            )}
          </div>
          <div className="side-panel-extra-info">
            {cluster}, {namespace}
          </div>

          {Object.values(sidebarLinksIds)
            .map(getNavObj)
            .map(bookmark => {
              return (
                <a href={'#' + bookmark.id} key={bookmark.id}>
                  <div className="side-button">{bookmark.title}</div>
                </a>
              )
            })}
        </Grid.Column>
      </Grid>
    </div>
  )
}

SidePanel.propTypes = {
  conciergeTeamId: PropTypes.string.isRequired,
  conciergeTeamLink: PropTypes.string.isRequired,
}

export default SidePanel
